import React from 'react'
import image_01 from '../../assets/images/cultures/saqqaq_text_01.jpg'

export const saqqaqPreview = 'People of the Saqqaq Paleo-Eskimo culture lived in southern Greenland continuously from 2500 to 800 BC before they mysteriously vanished. The Saqqaq or their ancestors belong to a group of people that have crossed the Bering Strait independently from the ancestors of present-day Native Americans and Inuit. The Saqqaq shares ancestry with Arctic north-east Asians and thereby show genetic similarity to present-day people on both sides of the Bering Sea. The Saqqaq were an offshoot of the Arctic Small Tool tradition, a broader cultural group developed around 2500 BC that had a highly distinctive toolkit that includes scrapers, burins, and blades used in composite arrows or spears. These people have introduced the latest weaponry innovation of their time – the bow and arrow – to the Arctic.'

function saqqaq (props) {
  return (
    <>
      <h2>The Saqqaq culture ({props.From} - {props.To} B.C.)</h2>
      <br />
      {saqqaqPreview}
      <br />
      <br />
      <img src={image_01} alt='The Saqqaq culture' />
      <legend> Archaeological remains of the Saqqaq culture in Sermermiut, Disko Bay, West Greenland coast.</legend>
      <br />
    The Saqqaq people did not build igloos but preferred to live in small tents. From there, the men organized hunting expeditions that hunted seals and other marine animals. They hunted with tools made of wood and silicified slate, quartzite, or other rock crystals, which made hunting extremely inefficient and dangerous as killing a large land or sea mammal required multiple hits, and it was not rare that the wounded animal would attack and hurt or kill some of the hunters. Drawing from other cultures, we can surmise that for Saqqaq teenagers, joining a hunting party was an honor, and a successful hunt was a rite of passage.
      <br />
      <br />
    The most well-known member of the Saqqaq culture was a Greenland Saqqaq adult whose genome was the first to be sequenced from hair found frozen in Greenland. He was a dark-eyed man with dry ear wax who was adapted to the unforgivable cold climate, as was evidenced by studying his genes involved in metabolism. With the publication of his genome, at artist reconstructed his face. At the site where the hair was found, there were tools, including arrows and flint knife. Over time, the Saqqaq was joined by other individuals from the same culture who comprised this test.
      <br />
      <br />
    The male Saqqaq included in this test carried the Y-chromosome haplogroup Q, which is most common among Native Americans today and extremely rare among Europeans. This lineage was thought to have arisen in the Altai Mountain region of Central Asia. The mitochondrial haplogroups of this male could not be obtained. However, two females of the Saqqaq culture belonged to haplogroup D, which split from haplogroup M, 60,000-35,000 years ago in Asia. It is now found among Northeast Asians and Native Americans.
    </>
  )
}
export default saqqaq
