import React from 'react'
import aboutIcon from '../../assets/images/homepage/about-icon.png'

function About (props) {
  return (
    <div className='about-wrap'>
      <div className='content'>
        <div className='icon'>
          <img src={aboutIcon} alt='about-icon' />
        </div>
        <div className='text'>
          <p>
            The <strong>MY BRONZE AGE ORIGINS TEST</strong> is the best ancient DNA
            test that employs ancient DNA from real bronze age people to
            help you discover your bronze age heritage (3300-300 BC). Using a
            groundbreaking paleogenomics technology that involves a unique set of
            mutations and over 500 Bronze Age people, My Bronze Age Origins Test
            focuses a beacon on the past providing answers to the most
            fundamental questions about ancient cultures, their relationships
            with each other and their connection with you. The test includes
            some of the most fascinating cultures including the Canaanites,
            Paleo-Eskimos, Minoan, Jomon, Hittite, Yamnaya, and 20 other
            worldwide bronze Age cultures.
          </p>
          <p>
            By analyzing over 500 Bronze Age individuals, we identified ten
            regional ancestries or gene pools that distinguish past populations.
            Six of those gene pools are available in the Beginner version of the
            test, and all ten are available in the advanced version. The genome
            of everyone, ancient or modern person, is represented as a
            combination of these gene pools (ranging from 0% to 100%). Different
            cultures consist of varying proportions of these regional
            components, which allows us to study them and compare them. This
            also allows us to find the most genetically similar Bronze Age
            cultures to your Bronze Age genomic makeup.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
