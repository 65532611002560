import React, { useContext, useState, useEffect } from 'react'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'
import { PreviewContext } from '../preview-page'
import AncestryCluster from './ancestry-clusters'

const originalCenter = [18, 0.42]

// function useTraceUpdate (props) {
//   const prev = useRef(props);
//   useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log('Changed props:', changedProps);
//     }
//     prev.current = props;
//   });
// }

function AncestryMap (props) {
  const {
    sidebarClickedCulture,
    auto,
    isMobile
  } = useContext(PreviewContext)

  const [zoom, setZoom] = useState(2)
  const [center, setCenter] = useState(originalCenter)
  // useTraceUpdate({
  //   sidebarClickedCulture,
  //   auto
  // })
  useEffect(() => {
    if (auto) {
      isMobile ? setZoom(0) : setZoom(2)
      setCenter(originalCenter)
    } else if (sidebarClickedCulture) {
      if (zoom < 5) setZoom(5)
      setCenter([sidebarClickedCulture.Lat, sidebarClickedCulture.Long])
    }
  }, [sidebarClickedCulture, auto, isMobile])

  return (
    <Map
      center={center}
      zoom={zoom}
      zoomControl={false}
      maxZoom={12}
      minZoom={isMobile ? 0 : 2}
      onViewportChanged={(e) => setZoom(e.zoom)}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <ZoomControl position='bottomright' />
      <AncestryCluster zoom={zoom} />
    </Map>
  )
}

export default AncestryMap
