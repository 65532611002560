import React from 'react'
import image_01 from '../../assets/images/cultures/Bell_beaker_eba_wales_text_01.jpg'
import image_02 from '../../assets/images/cultures/Bell_beaker_eba_wales_text_02.jpg'

export const bellBeakerWalesEBAPreview = 'The Welsh Bronze Age lasted from roughly 2100-650 BC, with a period of 2100-1400 BC considered the early Bronze Age. It is characterized by the appearance and spread of the Bell Beaker culture, who enjoyed the warmer climate of early Bronze Age Wales, their export of metal works, and the changes that they initiated in burial practices from the Neolithic communal tombs to the burial in a round mound at the center of stones and the appearance of grave goods. The Beaker folk farmed the land, clearing trees from mountainsides for their crops, raised sheep and cattle, and hunted deer. DNA evidence suggests that within a few hundred years of reaching Wales, the Bell Beaker folk replaced the local Neolithic pre-Beaker population that had dwindled due to declining agriculture or disease. During this time, the Beakers became the dominant culture and professed the use of their artifacts, including the more advanced weapons like socketed spearheads and lead to create bronze.'

function bellBeakerEBAWales (props) {
  return (<>
    <h2>Bell Beaker culture at the Early Bronze Age in Wales ({props.From} - {props.To} B.C.)</h2>
    <br />
    {bellBeakerWalesEBAPreview}
    <br />
    <br />
    People from the Bell Beaker culture (2800-1800 BC) had already spread through Europe from the Iberian Peninsula (where it is theorized, they originated as bronze traders) and Sicily to the Danube before they arrived in Britain. Despite their association with many artifacts and practices, including the use of metal, it is their distinctive pottery that they are most famous for. These potteries were highly decorated vessels shaped like an upside-down bell. The identification of these distinctive beakers is significant because it is the first archaeological evidence of wide-spread cultural diffusion—the spread of ideas. While beakers outside of Britain may have been used for rituals, drinking vessels, or food storage, most of the beakers discovered in Britain had funerary use. Early British beakers resembled those of the Rhine, though beakers were more stylistically similar to those in Ireland. The appearance of weapons, like the dirks and spearheads found in Newchurch, compared to their stark rarity in the early Bronze Age graves, indicates that the original farmers suffered repeated attacks that required them to arm themselves.
    <br />
    <br />
    There is relatively fewer examples of Beaker pottery in Wales compared to elsewhere. Building megalithic tombs fell out of fashion during this time, though there are examples of such tombs still being used in the 3rd century BC. The Welsh Beaker graves are in south-east Wales, and most of them lack grave goods, excepting one that had a flint knife. Wales stands in contrast to the rest of Britain—in Wales we find it is a higher proportion of male burials (nearly 80% compared to around 70% elsewhere) and a high number of Middle adults and no older adults. The many stone circles and ring cairns in Wales, as in the rest of Britain, suggests the Beaker people were cultic Pagans who regarded celestial objects. The Bell Beaker migrants did not come from Scotland, which was more isolated, but rather from South Yorkshire and southern England. This distribution fits the profile of young adventurers, treasure-seeking, willing to risk the hardship of living in remote and undeveloped lands, struggling with bears and packs of wolves, starving if their crops have failed, and dying at a young age. Their sacrifice was not in vain. In a few decades, the copper mining industry that these entrepreneurs developed became the most important industry in Britain and allowed their descendants to dominate British copper.
    <br />
    <br />
    <img src={image_01} alt='The Bell Beaker culture at the Early Bronze Age in Wales' />
    <legend> This monument lies in Mucklestone,  a small village in Staffordshire, along the England-Wales boundary, is a mysterious Early Bronze Age relic whose purpose is unclear with some believing it to be a tomb and others a stone circle (Source: Wikimedia commons).</legend>
    <br />
    The Brymbo Man, dated to 1600 BC, is one of the best known Welsh Bell Beakers. He was found in Brymbo, located between Chester and Wrexham, on the north side of Ruabon Mountain. Along with his incomplete remains, the archeologists who excavated the cist beneath the capstone found a small earthenware pot and a flint knife. His burial signifies the difference between Beaker and Neolithic burials in the former being individualistic sites that typically included personal belongings, particularly their trademark pottery.
    <br />
    <br />
    The male of this culture and period included in this test carried the Y-chromosome haplogroup R1b1a1a2a commonly found in Europe, Anatolia, the Caucasus. The rare mtDNA I3 haplogroup arose during the Holocene in Europe.
    <br />
    <br />
    <img src={image_02} alt='The Bell Beaker culture at the Early Bronze Age in Wales' />
    <legend> The Lligwy Burial Chamber was originally a Neolithic burial chamber in Lligwy, near the east coast of Anglesey, Wales, which has been in use throughout the Bronze and Iron Ages. The capstone, also called King Arthur’s Quoit, is one of largest capstones in Britain and is a remarkable example of the Late Neolithic engineering (Source: Wikimedia commons).</legend>
    <br />
    This genetic evidence, produced in 2018, adds much to our understanding of the Bell Beakers and allows us to appreciate their history. The term Bell Beaker is relatively new. It was introduced only in the late 19th and early 20th centuries to describe the people who used a distinctive pottery style was widely scattered throughout Europe and England at the end of the Neolithic era. The Bell Beakers followed the Corded Ware and Funnelbeaker cultures. The notion that “Beaker folk” roamed Europe fell in and out of favor among archeologists who were uncertain of the cultural shifts associated with them and to what extent they affected the local populations. Nonetheless, the understanding that the Beaker people represent a complex cultural phenomenon led to ongoing attempts to characterize this new culture, their beliefs, knowledge, skills, and demographic histories. Efforts to describe them have continued to this day.  Thanks to DNA evidence, we now know that they largely replaced the Neolithic populations in Britain and transmitted their knowledge as they expanded. The decline of the Bell Beakers that took place at the end of the Early Bronze Age is marked by the disappearance of the decorated Beaker vessels and changes in burial type. Bell Beakers throughout Europe were ‘converted’ into regionally different forms and decorative styles. The Beaker folk have changed Britain forever, and we now only begin to understand the magnitude of their changes on our genetic heritage and the depths of their culture.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/3j3CGhc'>"A History of Wales"</a></strong>
    <br />
    <br />
    <iframe title='corded ware germany book' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0140284753&asins=0140284753&linkId=6d2bacf72a5120dcf0edc3c4f6d02b27&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default bellBeakerEBAWales
