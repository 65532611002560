import React from 'react'
import image_01 from '../../assets/images/cultures/chongoni_text_01.jpg'
import image_02 from '../../assets/images/cultures/chongoni_text_02.jpg'
import image_03 from '../../assets/images/cultures/chongoni_text_03.jpg'
import image_04 from '../../assets/images/cultures/chongoni_text_04.jpg'

export const chongoniRockArtistsPreview = 'The Chongoni Rock art area can be found in the Central Region of Malawi. It consists of over 100 sites located in the forested hills of Malawi with granite paintings that describe the lives of the hunter-gatherers and later farmer communities between the Late Stone Age (37000 BC) and the Iron Age (1000 AD). Most of the paintings were made by women that aimed to record their natural environment.'

function chongoni (props) {
  return (<>
    <h2>Chongoni rock artists ({props.From} - {props.To} B.C.)</h2>
    <br />
    {chongoniRockArtistsPreview}
    <br />
    <br />
    <img src={image_01} alt='The Chongoni rock artists culture' />
    <legend>  Chongoni Rock-Art Area (Malawi) (source: Wikipedia)</legend>
    <br />
    As well as the symbols, rituals, and ceremonies that were meant to harness the natural forces in the world and bend them to their wills. The rituals were connected to fertility and the human life cycle.
    <br />
    <br />
    <img src={image_02} alt='The Chongoni rock artists culture' />
    <legend>  Chongoni Rock-Art Area (Malawi) (source: Wikipedia)</legend>
    <br />
    The painting consists primarily of white clay and red color, colors connected to womanhood.
    <br />
    <br />
    The rock art of Chongoni is considered of international importance. Not only they preserve the rare African rock art traditions, but they also record the transition from the foraging lifestyle to farming, the Ngoni invasion, and the arrival of Europeans.
    <br />
    <br />
    Some of the richest rock art concentrations made by women are found in the Chencherere site in the Chongoni Forest Reserve. The Chencherere II rock shelter is the only site in the Linthipe Valley that produced some of the oldest archeological materials. The site was initially inhabited by hunter-gatherers. They did not live in isolation and routinely engaged in commerce and social activities, including the production of rock art, producing marvelous red schematic paintings. The most important monument is the Mwana Wa Chencherere (‘child of Chencherere’) II, which has been studied for the past 50 years and considered a remarkable illustration of the use of the white style for drawing the figures representing the local society. Other rock art sites include Namzeze and Mphunzi.
    <br />
    <br />
    <img src={image_03} alt='The Chongoni rock artists culture' />
    <legend> Chongoni Rock-Art Area (Malawi), (source: Wikimedia Commons)</legend>
    <br />
    The choice of rock shelters was not incidental. These sites were used for the initiation ceremonies of young girls. Though it is unclear for how long they were painted, it is speculated that the painting continued into modern times and ended only in the 19th century, where they became hiding places for the people from the Ngoni invader groups, which coincided with the arrival of Europeans to Malawi, and the sacred painting stopped. Nonetheless, the monuments are still considered sacred by the Malawi, and some of them are still being used in ceremonies.
    <br />
    <br />
    <img src={image_04} alt='The Chongoni rock artists culture' />
    <legend>  Chongoni Rock-Art Area (Malawi) (source: Wikipedia)</legend>
    <br />
    An excavation of the site revealed bones and shell tools, an extensive collection of lithic and funal assembles, and human remains, two of which are included in this test. The two women belong to maternal haplogroup L0, which is one of two branches that split from the “Mitochondrial Eve,” the most ancient human maternal haplogroup. Today, L0 is found most commonly in Sub-Saharan Africa. It is found in the highest rates among the Khoisan, Namibia, and Botswana people.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/3j9OyP5'>"The First Africans: African Archaeology from the Earliest Toolmakers to Most Recent Foragers"</a></strong>
    <br />
    <br />
    <iframe title='The First Africans: African Archaeology from the Earliest Toolmakers to Most Recent Foragers' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0521847966&asins=0521847966&linkId=42e76db5daa9bfe060df74e5dfaf8e24&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default chongoni
