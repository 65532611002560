import React from 'react'
import image_01 from '../../assets/images/cultures/nicoleno_text_01.jpg'
import image_02 from '../../assets/images/cultures/nicoleno_text_02.jpg'
import image_03 from '../../assets/images/cultures/nicoleno_text_03.jpg'

export const sanNicolasIslandPreview = 'The San Nicolas Island (whose name was given in 1602 after Saint Nicholas of Myra) is the most remote of the Channel Islands off the coast of Southern California. After the disappearance of the Nicoleño, it remained officially uninhabited and has been used by the US military.'

function nicoleno (props) {
  return (<>
    <h2>Nicoleño culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {sanNicolasIslandPreview}
    <br />
    <br />
    <img src={image_01} alt='The Nicoleño culture' />
    <legend> West end of San Nicolas Island (source: Wikimedia Commons)</legend>
    <br />
    The Nicoleño were Native Americans who lived on  San Nicolas Island (California) whose population was exterminated in 1811 by a party of Aleuts and Russian fur traders from Russian Alaska. The last Nicoleño inhabited the island in 1835. The survivors of the massacre who were rescued from the island died off from diseases to which they had no immunity. The real name of the Nicoleño is unknown, and their name derived from the word Niminocotch, which the Chumash used for them.
    <br />
    <br />
    <img src={image_02} alt='The Nicoleño culture' />
    <legend> San Nicolas Island (source: Wikipedia)</legend>
    <br />
    Little is known about the Nicoleño in prehistoric times. What is known about them was gathered by the records of Juana Maria, the last survivor who described the Nicoleño culture and history for seven weeks before her death in a Uto-Aztecan language. Juana Maria’s story inspired American writer Scott O’Dell to write “Island of the Blue Dolphins,” one of the most popular young reader novels. Juana Maria’s cave has also been found. Moreover, hundreds of human remains and burial objects occasionally found on San Nicolas Island during construction work helped shed light on this culture.
    <br />
    <br />
    <img src={image_03} alt='The Nicoleño culture' />
    <legend>  Island night lizard on San Nicolas Island, California (source: Wikipedia)</legend>
    <br />
    The Uto-Aztecan language arrived in coastal Southern California between 2000 and 1000 BC and replaced the local language and possibly its culture. Numerous artifacts and artifacts styles from the Uto-Aztecan culture were found on the island. However, some things haven’t changed. Already 4000 years before the arrival of the Uto-Aztecan, the Nicoleños adapted their diet to rely solely on marine resources. This was an essential adaptation since the island was not only isolated by also hosted any animals of high nutritional value, and agriculture may have been too challenging or too risky. The Nicoleño fished Shellfish in the rocky shores, hunted birds, and hunted sea mammals that came to warm up in sandy shores. The population preferred living along with the northwest coast, the only place with freshwater springs.
    <br />
    <br />
    Like in the story of the Jew being rescued from an island on which he built two synagogues, one that he went to and one that he wouldn’t be caught dead in, even on the small and isolated San Nicolas island, the population was split into two subpopulations whose relationships with one another remain unclear.
    <br />
    <br />
    Artifacts recovered from burials include Olivella shell, bones, and stone beads, fishhooks, net sinkers, shell dishes, woven seagrass, and flaked stone and bone tools.
    <br />
    <br />
    All the four men included in this test belong to haplogroup Q, which is most common among Native Americans today and extremely rare among Europeans. This lineage was thought to have arisen in the Altai Mountain region of Central Asia. All the mitochondrial haplogroups of the four men and two women belong to haplogroup A, which is most commonly found among Native Americans. It has derived from haplogroup N in Asia some 30,000–50,000 years ago and did not diversify like other haplogroups. This indicates that the descendants of this maternal haplogroup have maintained coupling within the group after they experienced a long bottleneck some 20,000 years ago.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/3j8Vdcd'>"December's Child: A Book of Chumash Oral Narratives"</a></strong>
    <br />
    <br />
    <iframe title="December's Child: A Book of Chumash Oral Narratives" className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0520029305&asins=0520029305&linkId=e0e1807be62a9a8196c5ff49b2631041&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default nicoleno
