import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import { Link } from 'react-router-dom'
import ScrollArrow from './scroll-arrow'
import '../styles/_culture-page.scss'
// import cultureIcon from '../assets/images/cultures/corded-ware.png'
// let timeoutId = null

function CulturesPage (props) {
  const { cultures = [] } = props
  const localCultures = [...cultures]

  localCultures.sort((a, b) => b.From - a.From)

  // const refs = localCultures.reduce((acc, c) => {
  //   acc[c.idx] = React.createRef()
  //   return acc
  // }, {})
  //
  // const handleClick = (id) => {
  //   if (refs[id]) {
  //     refs[id].current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'start'
  //     })
  //   }
  // }
  //
  // useEffect(() => {
  //   const hash = window.location.hash
  //   const idx = +(hash && hash.replace ? hash.replace('#cult-', '') : undefined)
  //   if (!isNaN(idx)) {
  //     clearTimeout(timeoutId)
  //     timeoutId = setTimeout(() => handleClick(idx), 3000)
  //   }
  // }, [refs])

  return (
    <div className='cultres-page page-wrap'>
      <Navbar />
      <ScrollArrow />
      <div className='page-header-wrap culture-head'>
        <div className='content'>
          <div className='page-title'>
            <h1>Bronze Age Cultures</h1>
          </div>
        </div>
      </div>
      <div className='content-row gene-info' style={{ backgroundColor: 'transparent' }}>
        <div className='content-block'>
          <div className='description t-justify'>
            Each Bronze Age culture consists of individuals of a well-defined historical people whose skeletons were found in archeological sites and dated to the Bronze Age. The individuals were characterized, described, and dated by archeologists. Their cultural group was determined based on these and the materials left alongside the skeletons. The ancient DNA of these individuals was extracted from their bones and sequenced. The DNA sequence was used to determine their Bronze Age gene pool membership. In some rare cases, there is comprehensive literary evidence of the culture, such as in the case of Canaanites and Assyrians, wherein other cases, the evidence includes only pottery, animal bones, and other materials that survived.
          </div>
        </div>
      </div>
      <div className='grid section-text'>
        <div className='col-md-offset-3 col-md-6'>
          <ul className='section-text-toc pt30 pb30'>
            {localCultures.map((c) => <li key={'cult-toc-' + c.idx}>
              <Link to={'/cultures/' + c.linkName}>
                <span className='flex align-center flex--grow'>
                  <img className='mr5' src={c.smallIcon} alt='culture-icon' />
                  <span>{c.Culture}</span>
                </span>
                <span>{c.From} - {c.To} B.C.</span>
              </Link>
                                      </li>)}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CulturesPage
