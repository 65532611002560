import React from 'react'
import image_01 from '../../assets/images/cultures/bell_beaker_eba_england_text_01.jpg'
import image_02 from '../../assets/images/cultures/bell_beaker_eba_england_text_02.jpg'

export const bellBeakerEnglandEBAPreview = 'The English Bronze Age lasted from roughly 2,500-700 BC, with a period of 2,500-1,500 BC considered the early Bronze Age. It is characterized by the appearance and spread of the Bell Beaker culture and the use of knives and tanged spearheads.'

function bellBeakerEBAEngland (props) {
  return (<>
    <h2>Bell Beaker culture in the Early Bronze Age in England ({props.From} - {props.To} B.C.)</h2>
    <br />
    {bellBeakerEnglandEBAPreview}
    <br />
    <br />
    People from the Bell Beaker culture (2800-1800 BC) had already spread through Europe from the Iberian Peninsula, where they probably originated as bronze traders, and Sicily to the Danube before they arrived in Britain. As the Bell Beaker people crossed Europe, they interacted with the locals they encountered, sharing ideas and technology and interbreeding as well as fighting with their superior metal weapons. Despite their association with many artifacts and practices, including the use of metal, it is their distinctive pottery that they are most famous for. These potteries were highly decorated vessels shaped like an upside-down bell. The identification of these distinctive beakers is significant because it is the first archaeological evidence of wide-spread cultural diffusion—the spread of ideas. While beakers outside of Britain may have been used for rituals, drinking vessels, or food storage, most of the beakers discovered in Britain had funerary use. Early British beakers resembled those of the Rhine, though later beakers were more stylistically similar to those in Ireland.
    <br />
    <br />
    <img src={image_01} alt='The Bell Beaker culture in the Early Bronze Age in England' />
    <legend> A typical Beaker type vessel (Source: Wikipedia)</legend>
    <br />
    Genetic evidence shows that the Bell Beaker arriving in Great Britain had Yamnaya (originated in the Pontic Steppe) and Western European ancestries. Although the Bell Beakers may have been warlike and fought with bows and bronze weapons, the mixed genetic signature of the Bell Beakers in Great Britian suggest that the transition was relatively peaceful. The genetic evidence shows that these were two distinct people at this time living in Great Britain, and that by 2000 BC, 90% of the genetic signature of the Neolithic people had disappeared. The new signature included a mixture of the Neolithic and the European metal workers or solely the Bell Beakers.
    <br />
    <br />
    The Beaker folk farmed the land of Bronze Age Great Britain, clearing trees from mountainsides for their crops, raised sheep and cattle, and hunted deer. DNA evidence suggests that they replaced the local Neolithic pre-Beaker population, who had dwindled due to declining agriculture or disease within a few hundred years; however, the genome of the Beaker folk shows 10% Neolithic ancestry, suggesting some mixture between the Beaker folk and the Neolithic populations. The Beaker folk became the dominant culture and passed on the knowledge of their artifacts, including the more advanced weapons like socketed spearheads, and the use of lead to create bronze.
    <br />
    <br />
    England’s most famous Bell Beaker person is the Amesbury Archer— an early Bronze Age 40-year-old man (2380-2290 BC) and whose remains were discovered near Stonehenge in 2002. The Archer immigrated from Central Europe and was probably rich as he traveled to great distances in his life before settling in the Chalklands of Wessex. Indeed, his grave goods included five funerary pots, three copper knives, 16 flint arrowheads, tool kits for working with stone and metal, stone wrist-guards, and gold hair ornaments—England’s earliest gold objects. Altogether, these artifacts make up the largest cache of grave goods found from this period in Britain. He was found near a group of early Bronze Age people who shared a burial site at Boscombe Down in Amesbury and were known as the Boscombe Bowmen family. Their grave goods included flint arrowheads and tools as well as Beaker vessels and also a boar’s tusk and a bone toggle. The Amesbury Archer may be visited today at the Salisbury Museum
    <br />
    <br />
    <img src={image_02} alt='The Bell Beaker culture in the Early Bronze Age in England' />
    <legend> The Amesbury Archer as displayed in the Salisbury Museum (Source: Wikimedia commons).</legend>
    <br />
    Of the 13 males of the Beaker culture found in Great Britain, 12 carried the Y-chromosome haplogroup R1b1a1a2a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. One male carried the I2 haplogroup, which originated in Europe and is common in Eastern Europe and the Balkan. The mitochondrial haplogroups of the 13 males and 12 females included a wide variety of H, I, J, K, T, U, V, and X, which also supports a wide mixing with females throughout the continent. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup I dates to the Paleolithic Iran/Caucuses area and likely arrived with steppe peoples into Europe. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low frequencies in Europe. Haplogroup V has a very ancient Iberian ancestry occurring in only 4% of Europeans. It has spread during the Neolithic and has reached North Africa.
    <br />
    <br />
    This genetic evidence, produced in 2018, adds much to our understanding of the Bell Beakers and allows us to appreciate their history. The term Bell Beaker is relatively new. It was introduced only in the late 19th and early 20th centuries to describe the people who used a distinctive pottery style was widely scattered throughout Europe and England at the end of the Neolithic era. The Bell Beakers followed the Corded Ware and Funnelbeaker cultures. The notion that “Beaker folk” roamed Europe fell in and out of favor among archeologists who were uncertain of the cultural shifts associated with them and to what extent they affected the local populations. Nonetheless, the understanding that the Beaker people represent a complex cultural phenomenon led to ongoing attempts to characterize this new culture, their beliefs, knowledge, skills, and demographic histories. Efforts to characterize them have continued to this day.  Thanks to DNA evidence, we now know that they largely replaced the Neolithic populations in Britain and transmitted their knowledge as they expanded. The decline of the Bell Beakers that took place at the end of the Early Bronze Age is marked by the disappearance of the decorated Beaker vessels and changes in burial type. Bell Beakers throughout Europe were ‘converted’ into regionally different forms and decorative styles. The Beaker folk have changed Britain forever, and we now only begin to understand the magnitude of their changes on our genetic heritage and the depths of their culture.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/3lNJb9Q'>"The Amesbury Archer and the Boscombe Bowmen: Early Bell Beaker burials at Boscombe Down, Amesbury, Wiltshire, Great Britain: Excavations at Boscombe Down"</a></strong>
    <br />
    <br />
    <iframe title='corded ware germany book' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=1874350620&asins=1874350620&linkId=563c03b5e051a3f4cf9d2ba64757ba28&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default bellBeakerEBAEngland
