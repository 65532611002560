// https://stackoverflow.com/questions/38820724/how-to-display-leaflet-markers-near-the-180-meridian/

import React, { useContext, useState, useEffect } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { PreviewContext } from "../preview-page"

const iconMarker = new L.divIcon({
  html: null,
  className: 'custom-maker-icon',
  iconSize: L.point(16, 16, true)
})

// const createClusterCustomIcon = function (cl) {
//   const clMarkers = cl.getAllChildMarkers()
//   const icons = clMarkers.map(m => m.options.icon)
//   return L.divIcon({
//     html: `
//     <div class='marker-cluster-circle-${icons.length}'>
//       ${icons.map(ic => `<img src='${ic.options.iconUrl}' class='${ic.options.className}' />`).join('')}
//     </div>`,
//     className: 'leaflet-marker-icon marker-cluster marker-cluster-small leaflet-zoom-animated leaflet-interactive',
//     iconSize: L.point(0, 0, true)
//   })
// }

function AncestryCluster (props) {
  const { zoom } = props
  const {
    cultures,
    newCulture,
    sidebarClickedCulture,
    setSidebarClickedCulture,
    isMobile,
    auto
  } = useContext(PreviewContext)

  const [iconSize, setIconSize] = useState(45)

  useEffect(() => {
    if (zoom < 1) {
      setIconSize(20)
    } else if (isMobile) {
      setIconSize(32)
    } else {
      setIconSize(45)
    }
  }, [zoom, isMobile])

  if (!cultures) return null

  let selectedCulture = sidebarClickedCulture
  if (auto && newCulture) {
    selectedCulture = newCulture
  }

  const getDefaultMarker = (culture, iconSize, selectedCulture) => {
    // console.log([culture.Lat, culture.Long])
    const isSelectedCult = selectedCulture && selectedCulture.idx === culture.idx
    return <Marker
      key={'culture-' + culture.idx}
      position={[culture.Lat, culture.Long]}
      icon={isSelectedCult ? new L.icon({
        iconUrl: culture.xsmallIcon,
        iconSize: [iconSize, iconSize],
      }): iconMarker}
      onclick={() => setSidebarClickedCulture(culture, true)}
    />
  }


  if (zoom >= 3) {
    return cultures.map((c, idx) => getDefaultMarker(c, iconSize, selectedCulture))
  }
  // console.log(time, typeof time)
  const clusters = cultures?.reduce((clsts, c) => {
    if (selectedCulture && selectedCulture.idx === c.idx) {
      clsts.sc = [c]
    } else {
      clsts[c.cluster] = clsts[c.cluster] || []
      clsts[c.cluster].push(c)
    }
    return clsts
  }, {})

  return Object.keys(clusters).map((clustKey, idx) => {
    const cluster = clusters[clustKey]

    if (cluster.length > 1) {
      return (
        <MarkerClusterGroup key={'clustergroup-' + idx} showCoverageOnHover={false} >
          {cluster.map((cul, idx) => getDefaultMarker(cul, iconSize, selectedCulture))}
        </MarkerClusterGroup>
      )
    }
    return getDefaultMarker(cluster[0], iconSize, selectedCulture)
  })
}

export default AncestryCluster
