import React from 'react'

function formatPercentage (p, idx) {
  const p100 = p * 100
  let [n, decimals] = `${p100}`.split('.')
  if (!decimals) decimals = 0
  else if (+decimals > 0 && decimals.length > 2) {
    decimals = Math.round(decimals / (10 ** (decimals.length - 1)))
  }
  if (+decimals <= 0) decimals = ''
  return +`${n}.${decimals}`
}

function HorizontalBarChartComparision (props) {
  const {
    labels,
    percentagesA,
    percentagesB,
    colors,
    icons,
    titleA = 'You',
    titleB
  } = props
  const isDoubleShow = percentagesA && percentagesB
  const widthClass = isDoubleShow ? 'w50' : 'w100'

  const maxPercentage = Math.max(...percentagesB)

  function percentageToStyle (p, idx) {
    return {
      backgroundColor: colors[idx],
      width: (isDoubleShow ? p * 90 : (p / maxPercentage) * 90) + '%',
      color: 'transparent'
    }
  }

  const stylesA = isDoubleShow ? percentagesA.map(percentageToStyle) : {}
  const stylesB = percentagesB.map(percentageToStyle)

  const percentagesAFormatted = isDoubleShow ? percentagesA.map(formatPercentage) : percentagesA
  const percentagesBFormatted = percentagesB.map(formatPercentage)

  return (
    <div className={props.className || ''}>
      <div key='horizontal-bar-title' className='detwind-chart detwind-chart-title flex justify-between'>
        <div className='flex align-center w30 detwind-info-cont' />
        <div className='detwind-bar-cont justify-center flex--grow'>
          {isDoubleShow && <div className={widthClass + ' flex justify-center align-center detwind-bar-cont-border h100Percent'}>
            <span>{titleA}</span>
          </div>}
          <div className={widthClass + ' flex justify-center align-center h100Percent'}>
            <span>{titleB}</span>
          </div>
        </div>
      </div>
      {labels.map((l, idx) => (
        <div key={`horizontal-bar-${idx}`} className='detwind-chart flex justify-between'>
          <div className='flex align-center w30 detwind-info-cont'>
            <div><img src={icons[idx]} alt={'hb-icon' + idx} /></div>
            <div>{l}</div>
          </div>

          <div className={'detwind-bar-cont justify-center flex--grow' + (isDoubleShow ? '' : ' detwind-bar-cont-single')}>
            {isDoubleShow && <div className={widthClass + ' flex justify-end align-center detwind-bar-cont-border h100Percent'}>
              <span className='detwind-chart-label'>{percentagesAFormatted[idx]}%</span>
              <div style={stylesA[idx]} className='detwind-bar detwind-bar-left opacity70'>{idx}</div>
                             </div>}
            <div className={widthClass + ' flex align-center h100Percent'}>
              <div style={stylesB[idx]} className='detwind-bar detwind-bar-right'>{idx}</div>
              <span className='detwind-chart-label'>{percentagesBFormatted[idx]}%</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HorizontalBarChartComparision
