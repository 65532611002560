import React, { useState, useRef, useEffect, useContext } from "react"
import '../../styles/_timeline.scss'

import { useInterval, usePrevious, useKeyPress } from "../../services/hooks"
import { PreviewContext } from "../preview-page"
import { useWindowSize } from "../../services/hooks"

const timelineBoundaries = {}
function getTimelineBoundaries (culturesConf) {
  if (!timelineBoundaries.timelineEnd) {
    timelineBoundaries.timelineStart = Math.max(...culturesConf.map(({ From }) => From)) + 100
    timelineBoundaries.timelineEnd = Math.min(...culturesConf.map(({ To }) => To))
  }
  return timelineBoundaries
}

const defaultPauseTime = 1300
const intervalTime = 20

let timeoutId
let timeoutIdPause

function TimelineSlider({ culturesConf, setFilterdCultures, localTime, setIsEndTime }) {
  const {
    cultures,
    auto,
    setAuto,
    newCulture,
    setNewCulture,
    setLocalTime
  } = useContext(PreviewContext)
  const thumb = useRef(null)
  const [pauseTime, setPauseTime] = useState(defaultPauseTime)
  const [previousTimeJump, setPreviousTimeJump] = useState()
  const [nextTimeJump, setNextTimeJump] = useState()
  const [playSpeed, setPlaySpeed] = useState(8)
  const [checkCultures, setCheckCultures] = useState(true)
  const [timeJumps, setTimeJumps] = useState([])
  const [wait, setWait] = useState(false)
  // const [newCulture, setNewCulture] = useState(undefined)
  const prevCultures = usePrevious(cultures)
  // const prevNewCulture = usePrevious(newCulture)
  const idxsCurrentCultures = cultures?.map(c => c.idx)

  useKeyPress(' ', () => setAuto(!auto))

  const absTime = Math.abs(localTime)
  const { timelineStart, timelineEnd } = getTimelineBoundaries(culturesConf)
  const showThumbLabel = !auto && absTime !== timelineStart && absTime !== timelineEnd

  const checkFireCultureChange = (newTime) => {
    const activeCulturesIdxs = culturesConf.map((c, i) => {
      c.isActive = c.from <= newTime && newTime <= c.to
      if (c.isActive) return c.idx
      return null
    }).filter(v => v >= 0)
    activeCulturesIdxs.sort()
    const currentActiveCultIdx = cultures?.filter(c => c.isActive).map(c => c.idx)
    if (currentActiveCultIdx) currentActiveCultIdx.sort()
    const newFilteredCultures = culturesConf.filter(culture => culture.from <= newTime)
    const newCultFiltIdxs = newFilteredCultures.map(c => c.idx)
    newCultFiltIdxs.sort()

    if (newCultFiltIdxs.join('') !== idxsCurrentCultures?.join('') || activeCulturesIdxs.join('') !== currentActiveCultIdx?.join('')) {
      setFilterdCultures(newFilteredCultures)
    }
  }

  useEffect(() => {
    if (!timeJumps || timeJumps.length === 0) return
    if (previousTimeJump <= localTime && localTime < nextTimeJump) return
    let prevTimeJump = false
    for (const timeJump of timeJumps) {
      if (prevTimeJump && localTime < timeJump) {
        const nextTimeJumpIdx = timeJumps.indexOf(prevTimeJump) + 1
        const nextTimeJump = timeJumps[nextTimeJumpIdx]
        setPreviousTimeJump(prevTimeJump)
        setNextTimeJump(nextTimeJump)
        break
      }
      prevTimeJump = timeJump
    }
  }, [localTime, timeJumps, nextTimeJump, previousTimeJump])

  useEffect(() => {
    if (timeJumps.length > 0) {
      const range = nextTimeJump - previousTimeJump
      setPlaySpeed(range/(pauseTime/intervalTime))
    }
  }, [localTime, nextTimeJump, previousTimeJump, timeJumps, pauseTime])

  useInterval(() => {
    const toAdd = playSpeed
    // console.log(playSpeed)
    if (auto && absTime > timelineEnd && timeJumps.length > 0 && !wait) {
      let newTime = localTime + toAdd
      // console.log(newTime)
      let idx = -1
      if (newTime >= nextTimeJump) {
        idx = timeJumps.indexOf(nextTimeJump)
        newTime = nextTimeJump
      }
      if (idx !== -1) {
        // if (idx === timeJumps.length - 2) setPlaySpeed(12)
        setWait(true)
        setCheckCultures(true)
        setTimeout(() => setWait(false), pauseTime)
      } else {
        setCheckCultures(false)
      }
      setLocalTime(newTime)
    }
  }, intervalTime)

  useEffect(() => {
    if (culturesConf) {
      culturesConf.sort((a, b) => b.From - a.From)
      let times = culturesConf.map(c => - +c.From)
      times.unshift(-timelineStart)
      times.push(timelineEnd)
      times = [...new Set(times)]
      setTimeJumps(times)
    }
  }, [culturesConf, timelineEnd, timelineStart])

  useEffect(() => {
    if (!localTime) setLocalTime(-timelineStart)
  }, [])

  useEffect(() => {
    if (checkCultures) checkFireCultureChange(localTime)
    if (localTime === -timelineEnd) setIsEndTime(true)
  }, [localTime, checkCultures])

  // useEffect(() => {
  //   if (prevNewCulture !== newCulture && auto) {
  //     clearTimeout(timeoutIdPause)
  //     const currentPlayspeed = playSpeed
  //     timeoutIdPause = setTimeout(() => setPlaySpeed(currentPlayspeed), 1300)
  //     // console.log('setting 0')
  //     setPlaySpeed(0)
  //   }
  // }, [newCulture, auto, prevNewCulture])

  useEffect(() => {
    const idxsPreveCultures = prevCultures?.map(c => c.idx)
    if (idxsCurrentCultures) idxsCurrentCultures.sort()
    if (idxsPreveCultures) idxsPreveCultures.sort()

    if (idxsCurrentCultures?.join('') !== idxsPreveCultures?.join('')) {
      const cultureAdded = cultures?.filter(e => !idxsPreveCultures?.includes(e.idx))[0]
      const cultureRemoved = prevCultures?.filter(e => !idxsCurrentCultures?.includes(e.idx))[0]
      const newCul = cultureAdded || cultureRemoved
      setNewCulture(newCul)
    }
  }, [cultures, prevCultures])

  useEffect(() => {
    return () => {
      // console.log('clearing timeouts slider')
      [timeoutId, timeoutIdPause].forEach(tId => clearTimeout(tId))
    }
  }, [])

  const playBtnFn = (pauseTime = defaultPauseTime) => {
    clearTimeout(timeoutIdPause)
    if (!auto) setAuto(true)
    setPauseTime(pauseTime)
    // setPlaySpeed(playSpeed)
  }

  return (
    <div className="timeline">
      <div className="timeline-bars"><TimelineBars timelineStart={timelineStart} timelineEnd={timelineEnd}/></div>
      <input
        ref={thumb}
        name="timeline"
        className="timeline-slider"
        type="range"
        min={-timelineStart}
        max={-timelineEnd}
        step={1}
        value={localTime}
        onChange={(e) => {
          setCheckCultures(false)
          clearTimeout(timeoutId)
          setAuto(false)
          const val = +e.target.value
          setLocalTime(val)
          timeoutId = setTimeout(() => setCheckCultures(true), 500)
        }}
      />

      {showThumbLabel && (
        <span
          className="timeline-thumb-label"
          style={{
            left:
              Number(((absTime - timelineStart) * 100) /(timelineEnd - timelineStart)) + "%",
          }}
        >
          {Math.round(absTime)}
        </span>
      )}
      <span className='timeline-date'><TimelineMsg culture={newCulture}/></span>

      <div className='timeline-pauseplay'>
        <i className={ auto ? 'timeline-pause' : 'timeline-play'} onClick={() => auto ? setAuto(false) : playBtnFn()} />
        <i className='timeline-playfast icofont-before icofont-double-right icofont-nm' onClick={() => playBtnFn(defaultPauseTime / 2)} />
      </div>

    </div>
  );
}

function TimelineMsg (props) {
  const { culture } = props
  if (!culture) return null
  const { From, Culture, idx } = culture
  return (
    <p key={'TimelineMsg-' + idx } className="timeline-date-fade">
      {Culture} <span className="timeline-date-appear">appears at</span>{" "}
      {From} BC
    </p>
  )
}

function TimelineBars (props) {
  const { timelineStart, timelineEnd } = props
  const { width } = useWindowSize()
  const arr = []
  // const numbers = parseInt((timelineStart - timelineEnd) / 50)
  const numbers = parseInt(width / 50)

  for (let i = 0; i < numbers; i++) {
    arr.push(
      <span key={i} className="timeline-bar">
        {i === 0 && <span className="timeline-bar-label">{timelineStart} BC</span>}
        {i === numbers - 1 && <span className="timeline-bar-label timeline-bar-label-end">{timelineEnd} BC</span>}
      </span>
    )
  }
  return arr
}

export default TimelineSlider
