import React from 'react'
import image_01 from '../../assets/images/cultures/minoan_text_01.jpg'
import image_02 from '../../assets/images/cultures/minoan_text_02.jpg'
import image_03 from '../../assets/images/cultures/minoan_text_03.jpg'
import image_04 from '../../assets/images/cultures/minoan_text_04.jpg'
import image_05 from '../../assets/images/cultures/minoan_text_05.jpg'
import image_06 from '../../assets/images/cultures/minoan_text_06.jpg'
import image_07 from '../../assets/images/cultures/minoan_text_07.jpg'
import image_08 from '../../assets/images/cultures/minoan_text_08.jpg'

export const minoanPreview = 'In a mid-summer day between 1645 and 1500 BC, a devastating volcanic eruption blew up the Aegean island of Thera (now called Santorini). This was one of the most violent volcanic eruptions in history. Thera’s eruption was four or five times greater than Krakatoa and with an explosion’s energy of several hundred atomic bombs exploding in a matter of seconds. The eruption was so violent that it was recorded by the Chinese Bamboo Annals on the other side of the world. The explosion immediately killed of thousands of people, and the remaining were lost following the earthquakes and tsunamis that followed. The eruption deposited up to 7 m (23 ft) of pumice and ash, burying boats, houses, temples, and government buildings. Huge stones were found on the north coast of Crete situated 100 kilometers from Thera. This eruption marked the decline of the glorious Minoan culture, whose civilization disappeared shortly afterward, giving rise to the myth of Atlantis that Egyptian priests told Pericles and that Plato employed in his allegory on the hubris of nations. At the end of the story, Atlantis is submerged into the ocean after angering the gods. The impact of the Atlantic story had far exceeded the minor role it played in Plato’s story. At the hands of literary priests, it became a contemporary Sodom and Gemora story, and a powerful reminder of the unknown and mysterious forces that control our destiny. Atlantis has been “rediscovered” and remained “lost” numerous times since then, diverting the attention away from the direct victims of the eruptions – the Minoans.'

function minoan (props) {
  return (<>
    <h2>Minoan culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {minoanPreview}
    <br />
    <br />
    Centered on the island of Crete, the Minoans were the first major Aegean civilization whose achievements can be compared to those of ancient Egypt, Mesopotamia, and Anatolia. The Minoans developed an advanced maritime civilization that emerged in 3100 BC and flourished from 2000 to 1600 BC, until their final decline in 1050 BC. They traded as far as Egypt and the Near East and established settlements in the Aegean and in Western Anatolia. The island of Crete itself is situated on a trade route between Egypt and the Levant to Greece. Its shallow waters and sandy shores allow easy access to load and unpack goods from ships.
    <br />
    <br />
    <img src={image_01} alt='The Minoan culture' />
    <legend> Minoan fresco, showing a fleet and settlement Akrotiri (source: Wikimedia Commons)</legend>
    <br />
    The impressive palace in Knossos his a massive citadel and many lavishrooms with impressive decorations. It was associated with the legendary King Minos, after whom the civilization was named.
    <br />
    <br />
    <img src={image_02} alt='The Minoan culture' />
    <legend> Minoan Knossos Palace, king Minos, Crete, Greece (source: Wikimedia Commons)</legend>
    <br />
    The bull is centric to Minoan traditions, and mighty bulls feature in several myths . According to the legend, the gods sent King Minos a mighty bull as a sign of this right to rule the island. The king was so impressed with the bull that he refused to sacrifice it to the gods. As a punishment, Minos’s wife Pasiphae coupled with the bull and gave birth to an abomination – a half-man, half-bull creature known as the Minotaur. King Minos hired Dedalus to design and build the Labyrinth that would contain the abomination and sent seven young men and seven young women from Athens to feed it every year. Theseus eventually decapitated the beast. He found his way out of the maze, united the people, and become a king of Athens.
    <br />
    <br />
    <img src={image_03} alt='The Minoan culture' />
    <legend> The bull-leaping fresco, original. Found in Knossos palace, Crete, Greece. It dates around 1600 - 1450 BCE. Part of a five-panel composition, the iconic Toreador Fresco depicts an acrobat at the back of a charging bull. A second figure prepares to leap, while a third waits with arms outstreched (source: Wikimedia Commons)</legend>
    <br />
    The Minoans established a powerful polity whose power derived from their naval and maritime commercial supremacy. The Minoans were polytheists and their religion resembled the Near Eastern prehistoric religion. Alongside the bull, the Minoans worshiped the Great Goddess. Her icons—the labrys or double-headed axe and the serpent, being symbols of creation and death and rebirth, respectfully—have also been found in the excavated sites. The Minoans pleased the gods with offerings and human sacrifices.
    <br />
    <br />
    <img src={image_04} alt='The Minoan culture' />
    <legend> Goddess with arms raised, clay, LAte Minoan Crete, 1200-1100 BC (source: Wikimedia Commons)</legend>
    <br />
    <img src={image_05} alt='The Minoan culture' />
    <legend> A small golden double head minoan axe. Votive jewel. Found in Archalokori cave, 1700 - 1450 BC (source: Wikimedia Commons).</legend>
    <br />
    <img src={image_06} alt='The Minoan culture' />
    <legend> Snake goddess from the Palace of Knossos, Crete (source: Wikimedia Commons).</legend>
    <br />
    The Minoans were literate and used three different scripts, including the Linear B script, an early form of the Greek language also used on the mainland. That script involving images of animals, foods, or people and resembles Egyptian hieroglyphs.
    <br />
    <br />
    <img src={image_07} alt='The Minoan culture' />
    <legend> Minoan clay tablets Linear B (source: Wikimedia Commons)</legend>
    <br />
    The Minoan ancestors have probably emerged from Chalcolithic Anatolian settlers and from Neolithic Greece. The individuals included in this test were found in a cave in Lasithi and a cemetery near the Monastery of Odigitria. Pottery and stone vases alongside a large number of artifacts, including decorated vases, decorated sarcophagi, and boar’s tooth helmet, were found with the skeletons.
    <br />
    <br />
    <img src={image_08} alt='The Minoan culture' />
    <legend>  A sarcophagus found in Lashiti (source: Wikipedia)</legend>
    <br />
    The three males included in this have paternal haplogroups J and G, both of which originated in the Middle and Near East and were common from the Neolithic till modern days. They are minor lineages in Europe today but are major lineages in the Caucasus, southern Europe, Arabia, and the Levant. The maternal haplogroups of the three males and seven females include H, I, J, and U. Haplogroup H is commonly found in Europe today and is known to have Middle Eastern origins. It has also been found in Mesolithic hunter-gatherers, and exist in Europe before the arrival of agriculture. It is common in Europe today is of Middle Eastern origin, having been tracked out of Franco-Cantabrian region, and was around in Europe during the Paleolithic. Haplogroup I dates to the Paleolithic Iran/Caucuses area and likely arrived with steppe peoples into Europe. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup U, was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/350BfeJ'>"Minoans: A Captivating Guide to an Essential Bronze Age Society in Ancient Greece Called the Minoan Civilization"</a></strong>
    <br />
    <br />
    <iframe target='_blank' title='Minoans: A Captivating Guide to an Essential Bronze Age Society in Ancient Greece Called the Minoan Civilization' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=1647481430&asins=1647481430&linkId=7c8697f2876445f5c9bf719284361ac8&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default minoan
