const beginnerResultMapper = {};

[
  'East Africans',
  'Eurasian Hunter-Gatherers',
  'Canaan and Anatolia',
  'Albion and Alba',
  'North and South Paleoamerica',
  'Northeast Asia'
].map((v, idx) => (beginnerResultMapper[idx + 1] = v))

const advancedResultMapper = {};
[
  'East Africans',
  'European Bell Beakers',
  'Yamnaya',
  'Canaan and Anatolia',
  'Early Bronze Age Albion and Alba',
  'Atlantic-Celtic',
  'Middle-Late Bronze Age Albion and Alba',
  'South Paleoamerica and Oceania',
  'North Paleoamerica',
  'Northeast Asia'
].map((v, idx) => (advancedResultMapper[idx + 1] = v))

const geneticDistMapper = {
  'Early Paleo-Eskimo culture': 'Saqqaq culture',
  'Saqqaq culture': 'Saqqaq culture',
  'Corded Ware culture': 'Corded Ware culture in Germany',
  'Corded Ware culture in Germany': 'Corded Ware culture in Germany',
  'Bell Beaker culture England EBA': 'Bell Beaker culture England EBA',
  'Bell Beaker culture England MBA': 'Bell Beaker culture England MBA',
  'Bell Beaker culture Wales EBA': 'Bell Beaker culture Wales EBA',
  'Bell Beaker culture Wales MBA': 'Bell Beaker culture Wales MBA',
  'Bell Beaker culture Scotland EBA': 'Bell Beaker culture Scotland EBA',
  'Bell Beaker culture Scotland LBA': 'Bell Beaker culture Scotland LBA',
  Minoan: 'Minoan',
  Mycenaean: 'Mycenaean',
  'Jomon culture': 'Jomon culture',
  'Ain Ghazal culutre': 'Cannanite culture',
  'Saka culture': 'Saka culture',
  Canaanites: 'Sidonian Canaanite-Phoenician culture',
  'Chencherere culture': 'Chongoni rock artists',
  'Chongoni rock artists': 'Chongoni rock artists',
  'Deer Stone culture': 'Deer Stone culture',
  'The Cuncaicha Highland settlements': 'The Cuncaicha Highland settlements',
  'South-Western Iberian Bronze': 'South-Western Iberian Bronze',
  'Sintashta culture': 'Pre-Dorset culture',
  'Poltavka culture': null,
  'Yamnaya culture': 'Yamnaya culture',
  'Bell beaker in Spain': 'Bell beaker in Spain',
  'Protocogotas culture (Cogotas I)': 'Protocogotas culture (Cogotas I)',
  'Lapita culture': 'Lapita culture',
  Hittite: 'Hittite',
  Assyrian: 'Assyrian',
  'San Nicolas Island culture': 'San Nicolas Island culture',
  'Dong Son Culture': 'Dong Son Culture'
}

module.exports = {
  beginnerResultMapper,
  advancedResultMapper,
  geneticDistMapper
}
