import React from 'react'
import image_01 from '../../assets/images/cultures/hittite_text_01.jpg'
import image_02 from '../../assets/images/cultures/hittite_text_02.jpg'
import image_03 from '../../assets/images/cultures/hittite_text_03.jpg'
import image_04 from '../../assets/images/cultures/hittite_text_04.jpg'
import image_05 from '../../assets/images/cultures/hittite_text_05.jpg'
import image_06 from '../../assets/images/cultures/hittite_text_06.jpg'

export const hittitePreview = 'The Hittite culture emerged in Anatolia from an amalgam of Anatolian people between 1680 and 1180 BC. Anatolia is a very hilly country with many rivers that allow passage to ships as it connects Europe and Asia. During the Bronze Age, Anatolia was covered with thick forests, and its valleys were fertile. The warm Mediterranean climate allowed farmers to grow wheat, grapes, olives, and other fruits. The mountains, whose tops hosted the gods, were rich with minerals and metals, which made Anatolia one of the earliest metallurgic centers. The strategic location of Anatolia and its economic importance to trade and commerce made this region highly sought after and consequently very diverse as immigrants throughout the Old World and India were attracted to it. During the Bronze Age, it was controlled by the Hittite, the first people who learned how to produce iron. The Hittites founded their empire in Hattusa in north-central Anatolia around 1600 BC.'

function hittite (props) {
  return (<>
    <h2>Hittite culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {hittitePreview}
    <br />
    <br />
    <img src={image_01} alt='The Hittite culture' />
    <legend> The Sphinx Gate (Alaca Höyük, Turkey) (source: Wikipedia)</legend>
    <br />
    Hattusa was the magnificent capital of the Hittite Empire. It was heavily fortified with huge granaries and archives with tens of thousands of clay tablets written in the Hittite cuneiform writing, which helped decipher their writings and understand the Hittite culture. In addition to their mighty walls, the Hittite built fortresses within the city. They were ready for war.
    <br />
    <br />
    <img src={image_02} alt='The Hittite culture' />
    <legend> The Lion Gate in the south-west in Hattusa (source: Wikipedia)</legend>
    <br />
    Their economy resembled the feudal system with the farmer, shepherd, and artisan castes who worked in the fields and served the nobility. They were allowed to own property and marry. The nobles lived in large estates and served as the commanders of the Hittite army. Horses were vital to the Hittite as they pulled their heavy four-men-chariots. Their superior chariots and powerful iron weapons made the Hittite fierce fighters who not only defeated Egypt (1274 BC) but also wiped out the Kingdom of Mitanni (1350 BC).
    <br />
    <br />
    <img src={image_03} alt='The Hittite culture' />
    <legend> Image of Ramses II storming the Hittite fortress of Dapur (Da-pu-ru); from a wall picture on his temple at Thebes (Source: Wikimedia Commons).</legend>
    <br />
    The close relationships between the Hittite and Canaanites are echoed in the genealogical tree of Genesis 10. The Hittite’s ancestor Heth is the son Canaan and brother of Sidon. The Hittite with their mighty chariots and apocalyptic horses, left a strong impression on the Hebrews. They appear a dozen times as one of “seven nations greater and mightier than [the Hebrews]” whose lands will be eventually conquered by the Hebrews, which never happened, most likely because the Hittite never settled in Lebanon but in Anatolia. In practicality, the Hebrew must have realized that it is best to be allied with the scourge of Egypt and the sorrow of Mittani and managed not only to challenge the Hittite but also to develop reasonable relationships with them, not least due to their shared Canaanite origins. Consequently, the Hebrews intermarried with the Hittites, and many Hittites lived among them.
    <br />
    <br />
    Already in the Book of Genesis, we learn of Hittite Ephron, who sold Abraham the cave of Machpelah in Hebron. Uriah, the Hittite, was a captain in King David’s army and one of his “mighty men” (Chronicles 1, 11) who was ordered to the frontline to be killed so David would marry his wife. Like father, like son, we are later told that King Solomon also loved the Hittite women, which implied the existence of an alliance between the two people.
    <br />
    <br />
    That the Syrian army fled after they heard a noise of horses and chariots and surmised that the Israelites allied with none other than the Hittite and Egyptians tell us that such an alliance was not inconceivable:
    <br />
    <br />
    “For the Lord had made the host of the Syrians hear a noise of chariots, and a noise of horses, even the noise of a great host: and they said one to another, Lo, the king of Israel hath hired against us the kings of the Hittites, and the kings of the Egyptians, to come upon us (Kings 2, 6)”
    <br />
    <br />
    <img src={image_04} alt='The Hittite culture' />
    <legend> Statue of the Hittites king, Šuppiluliuma I in Hatay Archaeology Museum. He achieved fame as a great warrior and statesman (source: Wikipedia)</legend>
    <br />
    The Hittites were considered the people of the thousand gods and held a rich pantheon of gods, goddesses, demons, spirits, and sacred artifacts. As the Hittites expanded, their pantheon welcomed even more gods that enriched their mythology and contributed to the peaceful absorption of other cultures. It is said that before conquering a new region, the Hittites offered their foes a chance to surrender with the promise that they could continue to worship their own gods and goddesses and keep their own customs.
    <br />
    <br />
    <img src={image_05} alt='The Hittite culture' />
    <legend> Twelve Hittite gods of the Underworld in the nearby Yazılıkaya, a sanctuary of Hattusa (source: Wikipedia)</legend>
    <br />
    As part of their cultic activities, the Hittites practiced sacred prostitution, where they celebrated the mating of their deities with sexual acts between temple prostitutes and selected mates, possibly in public and perhaps with costumes of bulls (god) and lions (goddess). In later days, this practice focused on the mother-goddess. Prostitution was “sacred,” which meant, of course, that the women’s fees were not theirs to keep.
    <br />
    <br />
    Like their contemporaries, the Hittites practiced magic. Their archives record numerous rituals for healing, avenging, and success in battle. Some of these rituals resemble those from the Bible, like purifying the house from demons and preventing plagues with help from their numerous gods. The precarious art of communicating with the dead takes a central role in the Hittite myths and practice. The Hittite documents provide the missing details on how to bring back and connect with a spirit in places where the Hebrew Bible was stingy.
    <br />
    <br />
    <img src={image_06} alt='The Hittite culture' />
    <legend> Resurrecting the dead in the “Witch of Endor” by Nikolai Ge (1857) (source: Wikipedia).</legend>
    <br />
    The two individuals in this test were found in the Kaman-Kalehöyük site, situated in the Kızılırmak river basin in Central Anatolia. The building itself served as a public building or a trade center, but it is unlikely to be a burial site. One man had the paternal haplogroup J, which originated in the Middle and Near East and were common from the Neolithic till modern days. They are minor lineages in Europe today but are major lineages in the Caucasus, South Europe, Arabia, and the Levant. The men and women had the maternal haplogroups J and K. Haplogroup J can be found in about 12% of the present European population and is also found in the Middle East and North-East Africa. It originated in the Paleolithic (45,000 years ago), potentially in the Caucuses area. Haplogroup K originated some 30,000-22,000 years ago in Central, South, and West Europe, North Africa, and South and West Asia.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/3o2oyIZ'>"The Elements of Hittite"</a></strong>
    <br />
    <br />
    <iframe title='The Elements of Hittite' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0521115647&asins=0521115647&linkId=186665e2131dc08f50cb32c7ba6602f8&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default hittite
