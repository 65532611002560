import React from 'react'
import image_01 from '../../assets/images/cultures/dong_song_text_01.jpg'
import image_02 from '../../assets/images/cultures/dong_song_text_02.jpg'
import image_03 from '../../assets/images/cultures/dong_song_text_03.jpg'
import image_04 from '../../assets/images/cultures/dong_song_text_04.jpg'

export const dongSonPreview = 'According to Vietnamese legend, the first kingdom of Vietnam was founded by Hung Vuong in 2879 BC and that his dynasty lasted for over 2600 years. Though his reign and his kingdom are semi-mythical, the Dong Son Culture of 1000 BC-100 AD is considered to be the last remnants of that semi-mythical kingdom, giving truth to the legend, and is named after a village in northern Vietnam where many of its remains were found.'

function DongSon (props) {
  return (<>
    <h2>The Dong Son Culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {dongSonPreview}
    <br />
    <br />
    <img src={image_01} alt='The Dong Son Culture' />
    <legend> "Quốc tổ Hùng Vương" Trọng Nội (1966) (source: Wikipedia)</legend>
    <br />
    In the Red River Valley in northern Vietnam, people of the Dong Son culture cultivated rice, raised water buffalo and pigs, and fished and sailed in dugout canoes. They were also skilled bronze workers. They made hoes, scythes, plowshares made of bronze, replacing the stone tools used by earlier cultures in the area. The style of bronze work is very different from that produced by their contemporaries in China to their north, suggesting that they had developed this knowledge independently from their neighbors. The Dong Son were seafaring, and they traded their products throughout Southeast Asia.
    <br />
    <br />
    <img src={image_02} alt='The Dong Son Culture' />
    <legend> Sunset over the Red River (Hong River), view from Long Bien Bridge, Hanoi, Vietnam (Source: Wikipedia)</legend>
    <br />
    Around half of the bronze implements found in Dong Son graves were weapons, suggesting the prevalence of war in the society. They also made ornaments, tools—axes, spear and arrowheads, spades, hoes, and daggers—bells, bracelets, belt buckles, spool-shaped objects, and containers out of bronze. Iron implements have also been found at Dong Son sites, but they tended to be utilitarian and did not bear the decorations of this culture’s bronze work.
    <br />
    <br />
    <img src={image_03} alt='The Dong Son Culture' />
    <legend> Dong Son relic (source: Wikipedia)</legend>
    <br />
    The Dong Son drum the finest example of their metalwork. The drums were up to one meter tall and could way up to 100 kilograms. Over two hundred drums from this culture have been found—ranging from Indonesia to China. The drums were used in ceremonies—in prayers and rituals as well as at weddings and funerals—in trade and war. A Dong Son chief would beat the drum to summon tribal warriors.
    <br />
    <br />
    <img src={image_04} alt='The Dong Son Culture' />
    <legend> Bronze Drum made by the Dong Son II culture. Mid-1st millennium BC. (source: Wikipedia)</legend>
The drums had a flat beating surface with a star-shaped design in the middle and intricate and complicated decorations on all its surfaces. Their decorations included geometric patterns, motifs of animals—birds or deer—boats, people in feathered headdresses, or to depict scenes of the daily life of the people of this culture—houses or people dancing, threshing rice, or playing the drums—or military scenes.
    <br />
    The Dong Son culture had died out by transforming to subcultures under Chinese and Indian influences.
    <br />
    <br />
    The man included in the test belongs to parental haplogroup F, a prevalent haplogroup that has originated in South Asia and found today throughout South Asia, Southeast Asia, and parts of East Asia. The maternal haplogroups of the man and five women belong to haplogroups C, F, and M. Haplogroup C split from M around the Caspian Sea some 24,000 years ago. Haplogroup F has emerged in Southeast and East Asia and is still ubiquitous in those regions. Interestingly, it is not found among Native Americans, which suggests that its carriers did not arrive Siberia in Paleolithic times. Haplogroup M is the most common haplogroup in the world. It descended from the African haplogroup L3 and split into many other haplogroups.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/2T6dITW'>"The Origins of Ancient Vietnam (Oxford Studies in the Archaeology of Ancient States)"</a></strong>
    <br />
    <br />
    <iframe title='The Origins of Ancient Vietnam (Oxford Studies in the Archaeology of Ancient States)' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0199980888&asins=0199980888&linkId=096032db0eb180a910bf39a371d10009&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default DongSon
