import React from 'react'
import image_01 from '../../assets/images/cultures/Bell_beaker_eba_scotland_text_01.jpg'

export const bellBeakerScotlandEBAPreview = 'The Scottish Bronze Age lasted from roughly 2100-750 BC, with the period of 2100–1700 BC considered the Early Bronze Age. It is characterized by the appearance of the Bell Beaker culture who used the Killaha and Migdale flat axes, which were used to create a rock art in sites like Ri Cruin, speculated to be a resting place of high status, and the large mounds Nether Largie Mid/North. The Beaker folk farmed the land, clearing trees from mountainsides for their crops, raised sheep and cattle, and hunted deer. DNA evidence suggests that they replaced the local Neolithic pre-Beaker population who had dwindled due to declining agriculture or disease. During this time, the Beaker people became the dominant culture and professed the use of their artifacts, including the more advanced weapons like socketed spearheads, and lead to create the bronze. Yet although they were skilled metallurgists, the Scottish Beakers resisted change and were unable to cope with the success of the Welsh Bell Beakers, whose copper mining industry was superior to theirs.'

function bellBeakerEBAScotland (props) {
  return (<>
    <h2>Bell Beaker culture at the Early Bronze Age in Scotland ({props.From} - {props.To} B.C.)</h2>
    <br />
    {bellBeakerScotlandEBAPreview}
    <br />
    <br />
    People from the Bell Beaker culture (2800-1800 BC) had already spread through Europe from the Iberian Peninsula, where they likely originated as bronze traders, and Sicily to the Danube before they arrived in Britain. Despite their association with many artifacts and practices, including the use of metal, it is their distinctive pottery that they are most famous for. These potteries were highly decorated vessels shaped like an upside-down bell. The identification of these distinctive beakers is significant because it is the first archaeological evidence of wide-spread cultural diffusion—the spread of ideas. While beakers outside of Britain may have been used for rituals, drinking vessels, or for food storage, most of the beakers discovered in Britain had a funerary use. Early British beakers resembled those of the Rhine, though later beakers were more stylistically similar to those in Ireland.
    <br />
    <br />
    The pages telling the stories of the Scottish Bell Beakers have largely been omitted from the history books. The people who have settled Scotland and brought their unique knowledge in metallurgy and weaponry are rarely mentioned in the discussion of the Beaker people. Recent studies, however, highlighted their importance in the region and brought some of their stories to light. We now know that northeast Scotland contains over a hundred coastal and riverbank Beaker graves, most of them of males. Males were typically found buried with flint blades and bronze daggers. The graves of women were found with flint knives or necklaces. Of note is the grave of one Scottish Bell Beaker woman, speculated to be among the earliest migrants to Britain. She has a Caucasus ancestry, consistent with those of European Bell Beakers.
    <br />
    <br />
    Almost all the individuals there were found in short stone cists and contained mostly Beaker vessels. A few graves included arrowheads and bone belt rings. The Beakers’ beliefs involved the importance of rocks and minerals (mainly quartz), although we may never understand their importance and symbolic meaning, provided that the Beakers were metal traders we can guess that such “treasures” could be used as currency to allow the deceased to negotiate a better after-life or to better enjoy the after-life with those valuables. These hypotheses are supported by the additional ornaments used as decorations found in many of the graves.
    <br />
    <br />
    <img src={image_01} alt='The Bell Beaker culture at the Early Bronze Age in Scotland' />
    <legend> In the village Cannich located at the southern end of Strathglass, in the Highlands of Scotland, on the western edge of the Galloway forest Park there is a White Cairn chambered tomb that is unique to south-west Scotland (Source: Wikipedia).</legend>
    <br />
    The Bell Beakers were of average height, in modern terms, about 169±7 cm, with males being taller than females. Curiosity about their looks prompted Hew Morrison, a forensic artist to do a facial reconstruction for Ava—an early Bronze Age woman who lived 4,250 years ago and found in Achavanich in the Scottish Highlands in 1987. The depiction drew upon Ava’s DNA to determine that she likely had a Mediterranean complexion, straight black hair, and brown eyes. Ava’s DNA suggests that her direct ancestors have emigrated to Britain from the Netherlands. In other words, she had no Neolithic British ancestry. Though DNA analysis allows us to better determine what she may have looked like, she is still a woman of mystery. Burials in the Scottish Highlands from that time tended to be dirt graves and stone cairns. Ava, however, was given a tomb carved from rock.
    <br />
    <br />
    Of the seven males of the Beaker culture, five carried the Y-chromosome haplogroup R1b1a1a2a commonly found in Europe, Anatolia, the Caucasus, and its subbranches. One male carried the R1 haplogroup, whose origins are unclear and is found mainly in Eurasians, and the last male carried haplogroup F, which has originated in South Asia and is now common in West, South, Central, and East Asia. The mitochondrial haplogroups of the seven males and two females included a more limited variety of maternal haplogroups than observed in England including H, K, T, and U, suggesting a limited migration of Bell Beakers from England to Scotland and/or a limited mixture of the Scottish Bell Beakers with the locals, which led to the decline of their genetic diversity through the bottleneck effect. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low frequencies in Europe.
    <br />
    <br />
    The term Bell Beaker is relatively new. It was introduced only in the late 19th and early 20th centuries to describe the people who used a distinctive pottery style was widely scattered throughout Europe and England at the end of the Neolithic era. The Bell Beakers followed the Corded Ware and Funnelbeaker cultures. The notion that “Beaker folk” roamed Europe fell in and out of favor among archeologists who were uncertain of the cultural shifts associated with them and to what extent they affected the local populations. Nonetheless, the understanding that the Beaker people represent a complex cultural phenomenon led to ongoing attempts to characterize this new culture, their beliefs, knowledge, skills, and demographic histories have continued since then. The genetic evidence produced in 2018 added much to our understanding of the Bell Beakers and allowed us to appreciate their distinctiveness. We now know that they largely replaced the Neolithic populations in Britain and transmitted their knowledge as they expanded. The decline of the Bell Beakers that took place at the end of the Early Bronze Age is marked by the disappearance of the decorated Beaker vessels and change in burial type. Bell Beakers throughout Europe were ‘converted’ into regionally different forms and decorative styles. The Beaker folk have changed Britain forever, and we now only begin to understand the magnitude of their changes on our genetic heritage and the depths of their culture.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/2H41icT//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=B083M2BBLG&asins=B083M2BBLG&linkId=7ddac68559e4ab9de272df56e3c8c18c&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c'>"Celtic Tales: Fairy Tales and Stories of Enchantment from Ireland, Scotland, Brittany, and Wales"</a></strong>
    <br />
    <br />
    <iframe title='Celtic Tales: Fairy Tales and Stories of Enchantment from Ireland, Scotland, Brittany, and Wales' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=B083M2BBLG&asins=B083M2BBLG&linkId=7ddac68559e4ab9de272df56e3c8c18c&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default bellBeakerEBAScotland
