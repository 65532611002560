import React, { useContext, useState, useEffect } from 'react'
// import HorizontalBarChart from "../horizontal-bar-chart"
import HorizontalBarChartComparision from '../horizontal-bar-chart-comparision'
import { PreviewContext } from '../preview-page'
import Modal from 'react-modal'

// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)'
//   }
// }

function DetailsWindow (props) {
  const { show, culturesConf } = props
  const [sortedCultures, setSortedCultures] = useState([])
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const {
    sidebarClickedCulture,
    setSidebarClickedCulture,
    genePoolsConf,
    isMobile,
    report,
    setLocalTime
    // setLocalTimeIfLocalTimeIsSmaller
  } = useContext(PreviewContext)

  useEffect(() => {
    if (culturesConf) {
      const res = [...culturesConf]
      res.sort((a, b) => b.From - a.From)
      setSortedCultures(res)
    }
  }, [culturesConf])

  useEffect(() => {
    if (sortedCultures && sidebarClickedCulture) {
      const idx = sortedCultures.findIndex(c => c.idx === sidebarClickedCulture.idx)
      setHasPrev(idx !== 0)
      setHasNext(idx + 1 !== sortedCultures.length)
    }
  }, [sortedCultures, sidebarClickedCulture])

  if (sidebarClickedCulture && show) {
    const labels = genePoolsConf.map(gpc => gpc.name)
    const colors = genePoolsConf.map(gpc => gpc.color)
    const icons = genePoolsConf.map(gpc => gpc.smallIcon)
    const { Culture, preview, From, To, linkName, smallIcon, previewImage } = sidebarClickedCulture
    const culturePercentages = labels.map((l) => +sidebarClickedCulture[l])
    const resultPercentages = labels.map((l) => +report.result[l])

    const go = (next = true) => {
      let idx = sortedCultures.findIndex(c => c.idx === sidebarClickedCulture.idx)
      if (next) idx += 1
      else idx -= 1
      const goCul = sortedCultures[idx]
      setLocalTime(-+goCul.From)
      setSidebarClickedCulture(goCul, true)
    }

    const content = (
      <div className='detwind'>
        <div className='detwind-header'>
          <div className={'mr3 icofont-before icofont-rounded-left' + (hasPrev ? ' pointer' : ' cursor-not-allowed disabled-action')} onClick={() => hasPrev ? go(false) : null} />
          <div className='detwind-header-title'>
            <img className='detwind-header-icon mr5' src={smallIcon} alt='culture-icon' />
            <span>
              {Culture}&nbsp;<span className={isMobile ? 't-13' : 't-15'}>({From} BC - {To} BC)</span>
            </span>
          </div>
          <div className='flex'>
            <div className={'mr3 icofont-before icofont-rounded-right' + (hasNext ? ' pointer' : ' cursor-not-allowed disabled-action')} onClick={() => hasNext ? go() : null} />
            <div className='mr3 pointer icofont-before icofont-close' onClick={() => setSidebarClickedCulture(sidebarClickedCulture, false, false)} />
          </div>
        </div>
        <div className='detwind-main'>
          <div className='w100 detwind-previewImage' style={{ backgroundImage: `url(${previewImage})` }} alt='culture-map-preview' />
          <div className='detwind-main-cont'>
            <div className='detwind-title'>
              <span>Gene Pool composition</span>
              <a className='detwind-link link icofont-before icofont-file-text' rel='noopener noreferrer' target='_blank' href='/gene-pools'>
                The origins of the Gene Pools
              </a>
            </div>
            <HorizontalBarChartComparision
              className='detwind-charts'
              labels={labels}
              colors={colors}
              icons={icons}
              percentagesA={resultPercentages}
              percentagesB={culturePercentages}
              titleB={Culture}
            />
            {/* <HorizontalBarChart
              className="detwind-charts"
              labels={labels}
              colors={colors}
              icons={icons}
              percentages={percentages}
            />
            <div className="detwind-title">
              <span className='flex align-center'>Gene Pool composition of {Culture} <img className='ml10' src={smallIcon} alt={'culture-icon-title-' + idx}/></span>
            </div>
            <HorizontalBarChart
              className="detwind-charts"
              labels={labels}
              colors={colors}
              icons={icons}
              percentages={culturePercentages}
            /> */}
            <div className='detwind-title'>
              <span>{Culture} story</span>
            </div>
            {preview}
            <a className='ml5 detwind-link link icofont-before icofont-file-text' rel='noopener noreferrer' target='_blank' href={'/cultures/' + linkName}>Read more</a>
          </div>
        </div>
      </div>
    )

    return (isMobile ? <Modal isOpen>{content}</Modal> : content)
  } else {
    return null
  }
}

export default DetailsWindow
