import React from 'react'
import image_01 from '../../assets/images/cultures/jomon_text_01.jpg'
import image_02 from '../../assets/images/cultures/jomon_text_02.jpg'
import image_03 from '../../assets/images/cultures/jomon_text_03.jpg'
import image_04 from '../../assets/images/cultures/jomon_text_04.jpg'

export const jomonPreview = 'In Japanese prehistory, the Jōmon period officially stretches from 14,000 to 300 BC and is divided into several phases, of which the Middle (2500-1500 BC), Late (1500-1000 BC), and Final (1000-300 BC) overlap with the European Bronze Age. Note that the naming convention does not imply genetic and cultural continuity as the phases vary considerably in length and cultural diversity. The Middle Jōmon period started with the population explosion created during the Early Jōmon period when the climate was warm and food was abundant. In the Middle Jōmon period, large settlements were developed, for example, the Shitanoya site in Nishitokyo City. Excavation of this site unearthed a large number of highly decorated pottery and stone tools like round and chipped stone axe heads and flint arrowheads that appear like Neolithic innovations. The Jōmon houses were round and distributed in a ring surrounding a cemetery. The Jōmons founded their settlements along the rivers, where the Early Jōmon people fished using bone harpoons, fishhooks, and stone-age clubs. Dogs were domesticated to assist in hunting.'

function jomon (props) {
  return (<>
    <h2>Jōmon culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {jomonPreview}
    <br />
    <br />
    The transition to the Middle period is superficially marked by the appearance of spirals in ceramic patterns, stone axes with sharp edges, and grouped settlement. It is unclear what prompted those changes or what their significance was.
    <br />
    <br />
    <img src={image_01} alt='The Jōmon culture' />
    <legend> Middle Jōmon vessel (source: Wikipedia)</legend>
    <br />
    Similarly, the Late period is marked by communities located on the plains and more stable urbanization patterns, still without a clear understanding as to the social changes that influenced this behavior. We know, however, that these changes were not durable. At the end of the Jōmon period, the weather became colder and wetter, and the population declined sharply, likely due to food shortages and other environmental problems.
    <br />
    <br />
    <img src={image_02} alt='The Jōmon culture' />
    <legend> A jar with spirals. Final Jōmon, Kamegaoka style (source: Wikipedia)</legend>
    <br />
    The Jōmon people had rich spiritual lives, as is evidenced in their artifacts, jewelry, and other accessories excavated from their sites. Their beliefs, resembling early Shintoism, involved animism and shamanism. Over 15,000 Dogū (earthen figure), small humanoid and animal figurines, were dug out, mostly from Eastern Japan, and are exclusively belong to the Jōmon period. It is believed that the Dogū were made to resemble humans to extend magical protection from harm. Illnesses and other misfortunes could be passed to the Dogū, which would then be destroyed with the misery. That most of the Dogū are women with wide hips, suggest that they are representations of the Goddess, Eurasia's first and most widespread religion.
    <br />
    <br />
    <img src={image_03} alt='The Jōmon culture' />
    <legend> Jōmon Venus (source: Wikipedia)</legend>
    <br />
    It is agreed that the influx of Asiatic migrants brought with them their beliefs and values and contributed to the Jōmon culture from its early to late phases. This diversity makes it even more difficult to address questions of origins and ancestry and requires considering the Jōmon culture as a mix of Asiatic beliefs. Migrants from Siberia, where the weather became unstable during the early- Jōmon period, were probably responsible for many of the Jōmon innovations related to hunting as well as pottery styles.
    <br />
    <br />
    Since Jōmon sites were built on volcanic ash soil, no human or animal bones, wood, or other organic material are preserved, which makes it difficult to investigate the ancient Jōmon people and their culture. Fortunately, a Jōmon woman from the Final Jōmon period survived the destruction and is used in this test. The woman showed genetic relatedness to the Arctic populations of Eurasia, suggesting a shared Siberian origin. She has a high alcohol tolerance, a trait now largely lost in modern Japanese, although she was still genetically related to them. A facial reconstruction based on her genetic data indicated that her skin was slightly darker compared to modern Japanese. She has thin and fine hair and light brown eyes.
    <br />
    <br />
    <img src={image_04} alt='The Jōmon culture' />
    <legend> Reconstruction of a ~3,800 year old Jōmon woman from Rebun Island (source: Wikipedia).</legend>
    <br />
    Her maternal haplogroup was N, a very ancient macro-haplogroup that is speculated to have arisen in Asia and has since been split into multiple sub-haplogroups. Essentially, all the maternal haplogroups found outside of Africa descended from haplogroup N or its sibling M.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/359eSUn'>"Ancient Jomon of Japan (Case Studies in Early Societies)"</a></strong>
    <br />
    <br />
    <iframe title='Ancient Jomon of Japan (Case Studies in Early Societies)' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0521776708&asins=0521776708&linkId=80aa24212dbfb7e73b5fffbf46e25153&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default jomon
