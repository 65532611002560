import React from 'react'
import image_01 from '../../assets/images/cultures/pre-dorset_text_01.jpg'
import image_02 from '../../assets/images/cultures/pre-dorset_text_02.jpg'
import image_03 from '../../assets/images/cultures/pre-dorset_text_03.jpg'

export const preDorsetPreview = 'The Pre-Dorset Paleo-Eskimo culture existed in the Eastern Canadian Arctic in isolation from 3350 to 500 BC and preceded the Dorset culture. They were the descendants of the first people who settled the Arctic of the Americas some 6,000 years ago after they crossed the Bering Strait from Siberia. These Paleo-Eskimos are genetically distant from Neo-Eskimos and the ancestors of today’s Native American populations. Though they lived alongside other communities for thousands of years, they avoided mixing with them. This lack of mixture suggests differences among the populations’ resources, technologies, and religions.'

function preDorset (props) {
  return (<>
    <h2>Pre-Dorset culture ({props.From} - {props.To} B.C.)</h2>
    <br />
    {preDorsetPreview}
    <br />
    <br />
    <img src={image_01} alt='Pre-Dorset Paleo-Eskimo culture' />
    <legend> Drawing of a Sadlermiut man on inflated walrus skins bringing two dried salmon and a flint-headed arrow as a peace offering to newcomers. (1824) By Captain George Francis Lyon (Source: Wikimedia Commons)</legend>
    <br />
    The Arctic conditions were very unfavorable to the new immigrants. It is easy to get lost among over the 36,000 islands of the Arctic Archipelago. The winters are long and cold, and the summers are short and cool. Sunbathing is dangerous as solar radiation exists throughout the year. Some parts of the Arctic are permafrost (covered with ice year-round).
    <br />
    <br />
    <img src={image_02} alt='Pre-Dorset Paleo-Eskimo culture' />
    <legend>Permafrost in Devon Island, one of the largest members of the Arctic Archipelago.</legend>
    <br />
    The land was shared with vicious and hungry predators like the Arctic fox, Arctic wolves, and polar bears who are not afraid of humans and would happily feed on domesticated animals. Even today, incidents involving aggressive wolves are common.
    <br />
    <br />
    <img src={image_03} alt='Pre-Dorset Paleo-Eskimo culture' />
    <legend> Arctic wolf feeding on muskox carcass in Ellesmere Island (Source: Wikipedia)</legend>
    <br />
    Although the Pre-Dorset people were very poorly adapted to the harsh conditions of the Arctic, they nonetheless survived by hunting seals and other sea mammals. They did not build Igloos like the Inuit but instead lived in temporary tents, built using stone tools. Artifacts found in the pre-Dorset graves include polished burin-like tools used for engraving, soapstone lamps, needles, and a new style of harpoons made of bone or ivory. Pre-Dorsets are believed to have developed into Dorset Culture around 500 BC as a result of technological and economic changes that took place in the last half a millennia before their disappearance. The bow and arrow were found to be very limited in ice hunting, and as  sea mammals became the primary source of food for the Pre-Dorest, better ice-hunting techniques had to be adapted and new deadlier weapons to be fashioned. It is unclear whether there was a genetic continuity between these two cultures, but it is known that they left no genetic descendants in the New World. What became of these Paleo-Eskimos remains a mystery. All that is known is that the Paleo-Eskimos disappeared around 700 years ago. There is no good evidence of hostilities with their neighbors that would have killed off the population. Legends of the Paleo-Eskimo from the surviving neighboring people describe the Paleo-Eskimos as “gentle giants.” Nor does the evidence suggest they were wiped out by disease. Though the Paleo-Eskimos left no descendants, we may test our connectedness to this extinct group by comparing common ancestors.
    <br />
    <br />
    The 27 females included in this test belong to haplogroups A, C, and D, however, this apparent biodiversity is misleading as all those haplogroups are restricted to America and North-East Asia. Haplogroup A is most commonly found among Native Americans. It has derived from haplogroup N in Asia some 30,000–50,000 years ago and did not diversify like other haplogroups. This indicates that the descendants of this maternal haplogroup have maintained coupling within the group after they experienced a long bottleneck some 20,000 years ago. Haplogroup D split from haplogroup M 60,000-35,000 years ago in Asia. Some 35,000-10,000 years later, haplogroup C, also split from M around the Caspian Sea. Both D and C are found today among Northeast Asians and Native Americans.
  </>)
}
export default preDorset
