import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

function CopyrightsPage () {
  const genepoolIconsRefs = [
    'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Forest_elephant_family_%286987538203%29.jpg/440px-Forest_elephant_family_%286987538203%29.jpg',
    'https://commons.wikimedia.org/wiki/Category:Snow_in_Europe_in_January#/media/File:Scots_Pin_Ut%C3%B6,_January_2013.jpg',
    'https://en.wikipedia.org/wiki/Canaan#/media/File:Drawing_of_the_procession_of_the_Aamu_group_tomb_of_Khnumhotep_II_at_Beni_Hassan.jpg',
    'https://en.wikipedia.org/wiki/Albion#/media/File:White_cliffs_of_dover_09_2004.jpg',
    'https://en.wikipedia.org/wiki/Amazon_rainforest#/media/File:Amazon_CIAT_(2).jpg',
    'https://en.wikipedia.org/wiki/Zhangye_National_Geopark#/media/File:Linze,_Zhangye,_Gansu,_China_-_panoramio_(4).jpg',
    'https://commons.wikimedia.org/wiki/Category:Snowy_landscapes_in_Spain#/media/File:Alto_del_pozo_nevada_2005_vega_san_mateo_gran_canaria.jpg',
    'https://en.wikipedia.org/wiki/Caucasus#/media/File:%D0%AD%D0%BB%D1%8C%D0%B1%D1%80%D1%83%D1%81_%D1%81_%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%B0%D0%BB%D0%B0_%D0%93%D1%83%D0%BC%D0%B1%D0%B0%D1%88%D0%B8.JPG',
    'https://en.wikipedia.org/wiki/Bar_Pot#/media/File:Bar_Pot_-_geograph.org.uk_-_546909.jpg',
    'https://en.wikipedia.org/wiki/Royal_sites_of_Ireland#/media/File:Navan_Fort,_County_Armagh_-_geograph.org.uk_-_43871.jpg',
    'https://en.wikipedia.org/wiki/Dowth#/media/File:Dowth_Passage_Tomb.2.JPG',
    'https://commons.wikimedia.org/wiki/Category:Snowy_landscapes_in_Spain#/media/File:Presa_de_los_hornos_nevada_2002_tejeda_gran_canaria.jpg'
  ]

  const cultureIconRefs = [
    'https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Gazella_gazella.jpg/440px-Gazella_gazella.jpg',
    'https://en.wikipedia.org/wiki/File:Museo_Arqueol%C3%B3gico_Nacional_-_32252_-_Vaso_de_Ciempozuelos.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/3/3c/Yetholm_type_shield%2C_South_Cadbury_01.JPG',
    'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Lligwy_Burial_Chamber_in_Anglesey.jpg/440px-Lligwy_Burial_Chamber_in_Anglesey.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Museum_of_ScotlandDSCF6306.jpg/440px-Museum_of_ScotlandDSCF6306.jpg',
    'https://en.wikipedia.org/wiki/File:White_Cairn,_Glentrool_Village_-_geograph.org.uk_-_3531968.jpg',
    'https://en.wikipedia.org/wiki/Bryn_Celli_Ddu#/media/File:NMW_-_Bryn_Celli_Ddu_Stein.jpg',
    'https://en.wikipedia.org/wiki/Burin_(lithic_flake)#/media/File:Burin_213_5_Global.jpg',
    'https://en.wikipedia.org/wiki/Scraper_(archaeology)#/media/File:Gavaudun_Global_2009.0.212.3.jpg',
    'https://en.wikipedia.org/wiki/Bell_Beaker_culture#/media/File:Cazuela_(26798289912).jpg',
    'https://en.wikipedia.org/wiki/Lapita_culture#/media/File:PortVilaLapita.jpg',
    'https://en.wikipedia.org/wiki/Phoenicia#/media/File:Coin_of_Abdashtart_I,_Achaemenid_Phoenicia_(2).jpg',
    'https://en.wikipedia.org/wiki/J%C5%8Dmon_period#/media/File:Stone_statue,_late_Jomon_period.JPG',
    'https://en.wikipedia.org/wiki/Kazakh_art#/media/File:Applique_de_v%C3%AAtement_kazakh_(mus%C3%A9e_Guimet)_(5361665450).jpg',
    'https://uk.wikipedia.org/wiki/%D0%A4%D0%B0%D0%B9%D0%BB:Bull_leaping_minoan_fresco_archmus_Heraklion.jpg',
    'https://en.wikipedia.org/wiki/Agamemnon#/media/File:MaskOfAgamemnon.jpg',
    'https://en.wikipedia.org/wiki/Hattusa#/media/File:Sphinx_Gate,_Hattusa_01.jpg',
    'https://en.wikipedia.org/wiki/File:Lammasu.jpg',
    'https://en.wikipedia.org/wiki/Dong_Son_drum#/media/File:TrongDongNho.JPG',
    'https://en.wikipedia.org/wiki/Corded_Ware_culture#/media/File:Museum_f%C3%BCr_Vor-_und_Fr%C3%BChgeschichte_Berlin_031.jpg',
    'https://en.wikipedia.org/wiki/Nicole%C3%B1o#/media/File:San_Nicolas_box_artifacts.png',
    'https://en.wikipedia.org/wiki/File:Fibula_de_Codo-Bronce_final.jpg',
    'https://commons.wikimedia.org/wiki/File:Chongoni_Rock-Art_Area-110124.jpg',
    'https://commons.wikimedia.org/wiki/File:Escrita_sudoeste_by_Henrique_Matos_004_02.jpg',
    'https://en.wikipedia.org/wiki/Sculpture_of_Mongolia#/media/File:Balbal.jpg',
    'https://en.wikipedia.org/wiki/List_of_mammals_of_Peru#/media/File:Gato_andino.jpg',
    'https://commons.wikimedia.org/wiki/Category:Yamna_culture#/media/File:Yamna-1.jpg'
  ]

  return (
    <div className='c-page faq-wrap faq'>
      <Navbar />
      <div className='page-header-wrap'>
        <div className='content'>
          <div className='page-title'>
            <h1 className='t-black'>Icons copyrights<span /></h1>
          </div>
        </div>
      </div>
      <div className='grid faq-section section-text'>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <p>All icon images were obtained from Wikipedia or wikimedia from the following locations.
          From each image, a circular part was retained.
          </p>
        </div>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <h4>Gene Pool Icons</h4>
          {genepoolIconsRefs.map((i, idx) => <a href={i} className='icofont-before icofont-ui-press' target='_blank' key={'gp-icon-' + idx}>{i}</a>)}
        </div>
        <div className='col-md-offset-3 col-md-6 section-text'>
          <h4>Cultural icons</h4>
          {cultureIconRefs.map((i, idx) => <a href={i} className='icofont-before icofont-ui-press' target='_blank' key={'cult-icon-' + idx}>{i}</a>)}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default CopyrightsPage
