import React from 'react'
// import aboutIcon from "../../assets/images/homepage/about-icon.png";

function Package (props) {
  return (
    <div className='package-wrap' id='test'>
      <div className='content clusterColor'>
        <div className='package-col col-50'>
          <div className='title'>THE BRONZE AGE BEGINNER TEST</div>
          <div className='info'>
            A <strong>low</strong> resolution DNA test, that calculates the
            proportion of <strong>6 ancient gene pools</strong> and compares
            your results to <strong>7 ancient Bronze Age</strong> cultures.
          </div>
          <div className='genepool'>
            <div className='genepool-title'>6 Genepools</div>
            <ul>
              <li>
                <span className='clusterGold' />
                <span>East Africa (Africa)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Eurasian Hunter-Gatherers (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Albion and Alba (Great Britain)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Canaan and Anatolia (Middle East)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Northeast Asia (Asia and Oceania)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>North and South Paleoamerica (America)</span>
              </li>
            </ul>
          </div>
          <div className='genepool'>
            <div className='genepool-title'>7 Ancient bronze age cultures</div>
            <ul>
              <li>
                <span className='clusterGold' />
                <span>The Chenchere culture (Africa)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Spain Bell Beakers (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>
                  Early Bronze Age Bell Beaker in England (Great Britain)
                </span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Minoan (Greece)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Hittite (Middle East)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Deer Stone culture (Mongolia)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>Early Paleo-Eskimo culture (Canada)</span>
              </li>
            </ul>
          </div>
          <div className='price'>
            <strong>Only at $9.99*</strong>
            {/* <span style={{ color: 'red', textDecoration: 'none'}}>Special DNA Day price</span> */}
            <span>Normal Price $40*</span>
            <strong>Available in GenePlaza</strong>
          </div>
          <div className='price-btn'>
            <a href='https://www.geneplaza.com/app-store/75/preview' target='_blank' rel='noreferrer noopener'>Buy the test</a>
          </div>
        </div>
        <div className='package-col col-50'>
          <div className='title'>BRONZE AGE ADVANCED TEST</div>
          <div className='info'>
            A <strong>high</strong> resolution DNA test, that calculates the
            proportion of <strong>10 ancient gene pools</strong> and compares
            your results to <strong>25 ancient Bronze Age</strong> cultures.
          </div>
          <div className='genepool'>
            <div className='genepool-title'>10 Genepools</div>
            <ul>
              <li>
                <span className='clusterBlue' />
                <span>Early-Middle Bronze Age Albion and Alba (Great Britain)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Late Bronze Age Albion and Alba (Great Britain)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Atlantic-Celtic (Great Britain)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>European Bell Beakers (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Yamnaya (Europe)</span>
              </li>
              <li>
                <span className='clusterGold' />
                <span>East Africa (Africa)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Canaan and Anatolia (Middle East)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Northeast Asia (Asia)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>
                  South Paleoamerica and Oceania (South America and Oceania)
                </span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>North Paleoamerica (North America)</span>
              </li>
            </ul>
          </div>
          <div className='genepool'>
            <div className='genepool-title'>25 Ancient bronze age cultures</div>
            <ul>
              <li>
                <span className='clusterGold' />
                <span>The Chenchere culture (Africa)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Minoan (Middle East)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Mycenaean (Middle East)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Ain Ghazal culture (Middle East)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Canaanite (Middle East)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Hittite (Middle East)</span>
              </li>
              <li>
                <span className='clusterGreen' />
                <span>Assyrian (Middle East)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>
                  Early Bronze Age Bell Beaker in England (Great Britain)
                </span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>
                  Early Bronze Age Bell Beaker in Wales (Great Britain)
                </span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>
                  Early Bronze Age Bell Beaker in Scottland (Great Britain)
                </span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Corded Ware culture (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>South-Western Iberian Bronze (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Sintashta culture (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Poltavka culture (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Yamnaya culture (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Bell Beaker in Spain (Europe)</span>
              </li>
              <li>
                <span className='clusterBlue' />
                <span>Protocogotas culture (Cogotas I) (Europe)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Jomon culture (Asia)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Saka culture (Asia)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Deer Stone culture (Asia)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Dong son culture (Asia)</span>
              </li>
              <li>
                <span className='clusterPurple' />
                <span>Lapita culture (Oceania)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>The Cuncaicha Highland Settlements (South America)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>The Early Pale-Eskimo culture (Nort America)</span>
              </li>
              <li>
                <span className='clusterRed' />
                <span>San Nicolas Island culture (North America)</span>
              </li>
            </ul>
          </div>
          <div className='price'>
            <strong>Only at $20*</strong>
            {/* <span style={{ color: 'red', textDecoration: 'none'}}>Special DNA Day price</span> */}
            <span style={{ color: 'red', textDecoration: 'none' }}>Special Black Friday price</span>
            <span>Normal Price $60*</span>
            <strong>Available in GenePlaza</strong>
          </div>
          <div className='price-btn'>
            <a href='https://www.geneplaza.com/app-store/76/preview' target='_blank' rel='noreferrer noopener'>Buy the test</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Package
