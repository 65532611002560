import React, { useState, useRef, useContext, useEffect } from 'react'

import { PreviewContext } from '../preview-page'

function Sidebar (props) {
  const { cultures, setShowCultures, setShowMyResults } = useContext(PreviewContext)
  const culturesEl = useRef(null)
  const [triangleHeight, setTriangleHeight] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isAutoOpenend, setIsAutoOpenend] = useState(false)
  const { mobile, isEndTime } = props

  useEffect(() => {
    if (culturesEl && cultures) {
      setTriangleHeight(culturesEl.current.getBoundingClientRect().height)
    }
  }, [culturesEl, cultures, isCollapsed])

  useEffect(() => {
    if (!isAutoOpenend && isEndTime) {
      setIsCollapsed(false)
      setIsAutoOpenend(true)
    }
  }, [isAutoOpenend, isEndTime])

  if (!cultures) return null
  cultures.sort((a, b) => b.geneticDist - a.geneticDist)
  return (
    <div className={`sidebar ${mobile ? 'sidebar-mob' : ''} ${isCollapsed ? 'sidebar-collapsed' : ''}`}>
      <div className='sidebar-toggle' onClick={() => setIsCollapsed(false)}>
        <span className='pointer icofont-before icofont-rounded-right width100' />
      </div>
      <div className='sidebar-toggle sidebar-results-btn' onClick={() => setShowMyResults(true)}>
        <span className='t-15'>My Results</span>
      </div>
      <div className='sidebar-results'>
        <span className='icofont-before icofont-rounded-left pointer t-30' onClick={() => setIsCollapsed(true)} />
        <span className='t-15' onClick={() => setShowMyResults(true)}>My Results</span>
        <span className='icofont-before icofont-rounded-left pointer t-30' onClick={() => setIsCollapsed(true)} />
      </div>
      <div className='flex'>
        <div className='sidebar-similarity'>
          <div className='sidebar-similarity-head'>High genetic similarity</div>
          <div className='sidebar-similarity-triangle' style={{ height: triangleHeight }} />
          <div className='sidebar-similarity-bottom'>Low genetic similarity</div>
        </div>
        <div className='sidebar-main'>
          <div className='sidebar-main-head flex justify-between'>
            <span className='flex flex--grow justify-center pointer align-center'>
              <span className='a'>{cultures.length} Bronze Age Cultures</span>
              <span className='b' onClick={() => setShowCultures(true)}>Show all</span>
            </span>
          </div>
          <div ref={culturesEl}>
            {mobile && <div className='t-c mb10 t-12 t--uppercase t-color-light-grey'>Cultures are ordered by genetic similarity</div>}
            {cultures.map((c, idx) => <Culture key={`culture-${idx}`} culture={c} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

function Culture (props) {
  const { sidebarClickedCulture, setSidebarClickedCulture, doScrollIntoView, isMobile } = useContext(PreviewContext)
  const { culture } = props
  const cultureEl = useRef(null)
  useEffect(() => {
    if (!isMobile && doScrollIntoView && sidebarClickedCulture && sidebarClickedCulture.idx === culture.idx && cultureEl.current) {
      cultureEl.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [sidebarClickedCulture, culture, doScrollIntoView, isMobile])

  const showstory = () => setSidebarClickedCulture(culture, true, false) // before second arg was `isMobile`
  const isHovered = culture && sidebarClickedCulture && culture.idx === sidebarClickedCulture.idx

  return (
    <div ref={cultureEl} className={`sidebar-culture-cont ${isHovered && 'sidebar-culture-selected'}`} onClick={() => showstory()}>
      <div className='sidebar-culture'>
        <span className='mr5'>{culture.geneticDist}</span>
        <img className='sidebar-culture-icon' src={culture.smallIcon} alt='icon of culture' />
        <span className='sidebar-culture-info'>{culture.Culture}{' '}
          <span className='sidebar-culture-country'>({culture.Country})</span>
        </span>
        {/*! isMobile && <button className="sidebar-culture-btn" onClick={showstory}>
          Show results and story for this culture
        </button> */}
      </div>
    </div>
  )
}

export default Sidebar
