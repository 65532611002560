
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { PreviewContext } from './preview-page'

function Navbar (props) {
  const [navbarShow, setNavbarShow] = useState(false)
  const {
    className = '',
    isApp = false
  } = props
  // const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const { setNavbarHeight } = useContext(PreviewContext) || {}

  useEffect(() => {
    // setHeight(ref.current.clientHeight)
    if (setNavbarHeight) setNavbarHeight(ref.current.clientHeight)
  }, [ref, setNavbarHeight])

  // const title = isApp ? 'My Bronze Age Origins' : 'Bronze Age Calculator'
  const title = 'My Bronze Age Origins'

  const menuContent = (
    <>
      {!isApp && <li><Link to='/'>Home</Link></li>}
      {!isApp && <li><Link to='/preview'>Preview</Link></li>}
      {isApp && <li><Link to='/app'>My results</Link></li>}
      <li><Link to='/cultures'>Cultures</Link></li>
      <li><Link to='/gene-pools'>Gene Pools</Link></li>
      <li><Link to='/faq'>FAQ</Link></li>
    </>
  )

  return (
    <div className={className + ' navbar_wrap'} ref={ref}>
      <div className='logo'>
        {!isApp && <Link to='/'>{title}</Link>}
      </div>
      <div className='menu'>
        <div className='nav_right'>
          <div className='icon desktop-hide'>
            <button onClick={() => setNavbarShow(!navbarShow)}>
              <span />
              <span />
              <span />
              <span />
            </button>
            {
              navbarShow
                ? <ul className='mobile-menu'>
                  {menuContent}
                  </ul>
                : null
            }
          </div>
          <ul className='mobile-hide'>
            {menuContent}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar
