import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import '../styles/_culture-page.scss'
// import cultureIcon from '../assets/images/cultures/corded-ware.png'

const BackLink = () => <Link className='icofont-before icofont-long-arrow-left' to='/cultures'>To all BA cultures</Link>

function CulturesPageId (props) {
  const { cultures = [], cultureName, isPreview } = props

  const culture = cultures.find(c => c.linkName === cultureName)
  let content = null
  if (culture) {
    const Story = culture.story
    const storyPreview = culture.preview
    content = (
      <div key={'cult-story-' + culture.idx} className='content-row'>
        <div className='content-block'>
          <BackLink />
          <div className='icon'><img src={culture.icon} alt='culture-icon' /></div>
          {/* <div className='t-b title'>{c.Culture}</div> */}
          <div className='description'>
            {isPreview ? storyPreview : <Story {...culture} />}
          </div>
          {isPreview && <HashLink className='display-block mb15' to='/#test'>Buy the report to read the full story</HashLink>}
          <BackLink />
        </div>
      </div>
    )
  }

  return (
    <div className='cultres-page page-wrap'>
      <Navbar />
      {content}
      <Footer />
    </div>
  )
}

export default CulturesPageId
