import React from 'react'
import image_01 from '../../assets/images/cultures/saka_text_01.jpg'
import image_02 from '../../assets/images/cultures/saka_text_02.jpg'
import image_03 from '../../assets/images/cultures/saka_text_03.jpg'
import image_04 from '../../assets/images/cultures/saka_text_04.jpg'
import image_05 from '../../assets/images/cultures/saka_text_05.jpg'
import image_06 from '../../assets/images/cultures/saka_text_06.jpg'

export const sakaPreview = 'The Saka were some of the most fascinating people of the Old World. These nomadic equestrian tribes roamed from the Eurasian Steppe through the Tarim Basin in China from the 9th century BC.'

function saka (props) {
  return (<>
    <h2>Saka culture in Kazakhstan ({props.From} - {props.To} B.C.)</h2>
    <br />
    {sakaPreview}
    <br />
    <br />
    <img src={image_01} alt='The Saka culture in Kazakhstan' />
    <legend>  The mountainous region of the Tian Shan in south-eastern Kazakhstan (source: Wikipedia)</legend>
    <br />
    They traded silk, horses, grain, and luxury goods with urban populations. They spoke a Scythian language and were part of the broader Scythian culture. There is some ambiguity in ancient literature over who the term Saka referred to. Arrian of Nicodemia explained that the Saka were Asian Scythians, implying that the Scythians were comprised of varied tribes and lived in both Asia and Europe. Yet Herodotus wrote that the Persians referred to all Scythians as Saka. And according to the Babylonians, the Saka were the Gimirrai—the descendants of Gomer, the grandson of Noah, and the father of Ashkenaz.
    <br />
    <br />
    Herodotus described them as wearing trousers and having “about their heads caps which were carried up to a point and set upright and stiff.” (Histories, Book VII, para. 64.) Saka wearing these hats may be seen on a relief of the Apadana in Persepolis.
    <br />
    <br />
    The Saka were the horse lords of the ancient world. They domesticated the horse around 3500 BC and by 2000 BC developed the first calvary as a form of warfare or by pulling chariots. Their culture was horse centered, and every warrior owned several horses with whom he developed strong bonds.
    <br />
    <br />
    <img src={image_02} alt='The Saka culture in Kazakhstan' />
    <legend> Depiction of a mounted warrior from the Pazyryk burials, (300 BC) (source: Wikipedia)</legend>
    <br />
    Their horsemanship and metalwork made them major military power of their time. Herodotus notes they “carried native bows and daggers, and besides this axes of the kind called sagaris.” (Histories, Book VII, para. 64.). But it was not the horses or the metallurgy that captured the Greek imagination about the Saka-Scythians. It was the Amazons.
    <br />
    <br />
    <img src={image_03} alt='The Saka culture in Kazakhstan' />
    <legend> Amazons and Scythians, by Otto van Veen, Kunsthistorisches Museum, Vienna (source: Wikipedia)</legend>
    <br />
    The Amazons, the fierce warrior women of the exotic east who could fight and battle as well as Greek men, were stuff of Old World legends. It was said that the Amazon remained virgins until they proved their worth in war, that they kill or send off their male offspring, that they cut their breast to improve arrow shooting, and, of course, that they practiced polyamory and “free love.” In reality, Saka’s women enjoyed a great deal of freedom compared to women of other cultures, and some of them were warriors, a fundamentally revolutionary concept in those days. Traces of the women warrior in the steppe nomadic lifestyle still exist today. Nmontols and Kazak train children to become skilled riders and archers irrespective of their gender.
    <br />
    <br />
    <img src={image_04} alt='The Saka culture in Kazakhstan' />
    <legend>  A hippeis rider seizes a mounted Amazonian warrior (armed with a labrys) by her Phrygian cap, Roman mosaic emblema (marble and limestone) from Daphne, a suburb of Antioch-on-the-Orontes (now Antakya in Turkey), second half of the 4th century AD (source: Wikipedia)</legend>
    <br />
    The Saka and Scythians were mighty and fearful people, known not to be trifled with. The terrible sight of men and perhaps women warriors swarming the land on wild beasts while shooting arrows or throwing spears was the stuff of Old World nightmares. These sight made deep impressions that lasted for centuries, not only on the Greek, Persians, and Romans but also on the Hebrews. The name “Ashkenaz” is the Biblical Hebrew adaptation of the Scythians (Saka), which was rendered in Assyrian and Babylonian documents of the 7th century BC as askuza, called in English by the Greek equivalent “Scythian.” Formally, the Scythians (Saka) are mentioned in the Hebrew Bible only three times: twice in the genealogical table of nations as one of the sons of Gomer (Genesis 10) and once as one of the three powerful Kingdoms that would destroy Babylon, which the Hebrew despised: “Lift up a banner in the land! Blow the trumpet among the nations! Prepare the nations for battle against her; summon against her these kingdoms: Ararat, Minni and Ashkenaz. Appoint a commander against her; send up horses like a swarm of locusts. Prepare the nations for battle against her—the kings of the Medes, their governors and all their officials, and all the countries they rule.”  (Jeremiah 51, 27).
    <br />
    <br />
    Other mentions of the Scythians are indirect, either mentioning a horrible disaster coming from the north or using code names. Prophecies on Gog and Magog (or Gog from Magog) who will rise for a final battle with Israel appear in the Hebrew Bible, New Testament, and the Quran.
    <br />
    <br />
    “Son of man, prophesy and say unto Gog, Thus saith the Lord God; In that day when my people of Israel dwelleth safely, shalt thou not know it? And thou shalt come from thy place out of the north parts, thou, and many people with thee, all of them riding upon horses, a great company, and a mighty army: And thou shalt come up against my people of Israel, as a cloud to cover the land; it shall be in the latter days, and I will bring thee against my land, that the heathen may know me, when I shall be sanctified in thee, O Gog, before their eyes. Thus saith the Lord God; Art thou he of whom I have spoken in old time by my servants the prophets of Israel, which prophesied in those days many years that I would bring thee against them? And it shall come to pass at the same time when Gog shall come against the land of Israel, saith the Lord God, that my fury shall come up in my face.” (Ezekiel 38, 14-18).
    <br />
    <br />
    “[Satan will be imprisoned for 1000 years and] When the thousand years are over, Satan will be released from his prison and will go out to deceive the nations in the four corners of the Earth—Gog and Magog—and to gather them for battle. In number they are like the sand on the seashore. They marched across the breadth of the earth and surrounded the camp of God’s people, the city he loves.” (Book of Revelations 20, 7-10).
    <br />
    <br />
    “O two-horned, the Gog and Magog are spreading chaos in the land. Can we pay you, to build between us and them a wall?” (Quran 18)
    <br />
    <br />
    The prophecies vary on the outcome of the battle, and since the Israelites have been defeated numerous times, it is also unclear to which battle the prophecies relate or whether it has already happened. However, Josephus noted that the Magog people are the Scythians.
    <br />
    <br />
    <img src={image_05} alt='The Saka culture in Kazakhstan' />
    <legend> Gog and Magog of the apocalypse (source: Wikimedia Commons)</legend>
    <br />
    It is not surprising that the ancestors of modern-day Ashkenazic Jews, the Irano-Greco-Roman pagans living along the Black Sea, have adopted the name “Ashkenaz” to themselves, after the mighty Saka-Scythians.
    <br />
    <br />
    A rich Saka burial mound (or kurgan tomb) in Northeast Kazakhstan was recently excavated, unearthing around 3000 golden objects, including plates, jewelry—golden beads and chains with precious stones—and ornaments of animals from around 1000-800 BC. Additionally, the burial mound of a Saka “golden woman,” possibly a Saka princess from around 1000 BC, was found in Western Kazakhstan—her grave containing gold jewelry, gold and silver vessels, makeup, a headdress, and a horse bridle. The exquisite metalwork in these graves is a testament to the Saka’s sophistication despite their nomadic lifestyle, and the fine metalwork of the animal ornaments—in particular deer and lions—is characteristic of the Saka. In 2013, a woman dated back to the beginning of the Bronze Age had been found in the Urdzhar district in East Kazakhstan. Her headwear was made of red silk and her clothes of wool. Based on her skeleton and DNA findings, this “Urdzhar Princess” was restored.
    <br />
    <br />
    <img src={image_06} alt='The Saka culture in Kazakhstan' />
    <legend> Deer - Kazakh garment applique, 8-7th century BC (source: Wikipedia)</legend>
    <br />
    The DNA of two men found in more modest burial mounds in Northeast Kazakhstan has been sequenced. One man from around 800-773 BCE was around 25-35 years old at his death and was buried with an iron knife and three bronze arrowheads, suggesting he was a skilled archer—either as a hunter or in the Saka’s military tradition. The other lived at some point between 810 and 540 BCE and was buried with a whetstone, a common funerary item found in the graves of Saka males. The man paternal haplogroup was E, which has arisen in Africa and is common among Afroasiatic people. The maternal haplogroups of the two males and five females included in this test are very diverse and include C, H, N, and U. Haplogroup C had split from haplogroup M between the Caspian Sea and Lake Baikal some 24,000 years ago. Haplogroup H is commonly found in Europe today and is known to have Middle Eastern origins. It has also been found in Mesolithic hunter-gatherers, and exist in Europe before the arrival of agriculture. It is common in Europe today is of Middle Eastern origin, having been tracked out of Franco-Cantabrian region, and was around in Europe during the Paleolithic. Haplogroup N is likely of Middle Eastern Origin between 12,000 and 30,000 years ago. It is common in Europe today and is associated with early Neolithic farmers. Haplogroup U was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/346PFuo'>"The Scythians: Nomad Warriors of the Steppe"</a></strong>
    <br />
    <br />
    <iframe title='The Scythians: Nomad Warriors of the Steppe' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0198820127&asins=0198820127&linkId=6467961baf9f751a348cb86b21b618b2&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default saka
