import React from 'react'
import image_01 from '../../assets/images/cultures/cuncaicha_text_01.jpg'

export const cuncaichaHighlandPreview = 'The Andes form the longest continental mountain range in the world along the western edge of South America. They are 7,000km long, 200-700km wide, and have an average height of about 4,000m. The Andes cross Argentina, Chile, Peru, Bolivia, Venezuela, Colombia, and Ecuador and include many active volcanoes. The richness of the Andes’ fauna (almost 600 species of mammals and 1700 species of birds) and their plethora of stones, firewood, shelter, and freshwater have attracted humans since the Pleistocene. In both Peru and Bolivia, a wide plain of tropical forests that extend to the Atlantic and can sustain multiple populations are available. The Andes were to the Paleo-Indians what the Nile was to the Egyptians. The mountain range provided for all the human needs and the settlers depended very little on import, they expanded over time.'

function cuncaicha (props) {
  return (<>
    <h2>The Cuncaicha Highland settlers (Peru) ({props.From} - {props.To} B.C.)</h2>
    <br />
    {cuncaichaHighlandPreview}
    <br />
    <br />
    <img src={image_01} alt='The Cuncaicha Highland settlers (Peru)' />
    <legend> The Andes (source: Wikipedia).</legend>
    <br />
    At a height of 4480 meters above sea level, the Cuncaicha rochshelter is the highest known Pleistocene settlement in the Peruvian Andes. It is located in the Pucuncho Basin and surrounded by three volcanos. Living in high altitudes where the air is thin is not a trivial task. It requires multiple genetic adaptations to allow the body to constantly produce excess hemoglobin (the molecule that carries the oxygen to the cells) to compensate for the low oxygen in the environment.
    <br />
    <br />
    Hunter-gatherer Palaeo-Indian remains from 12,400-14,500 years ago tell us of the importance of this site to the development of the Andes’ civilizations and to our understanding of adaptations to harsh environments. The inhabitants welcomed the generosity of the Andes and built tools that they fashioned from stones, beads from animal bones, and minerals from their surroundings. For homes, they used caves and rock made shelters. The settlers were not isolated and have engaged with other people and traders from whom they acquired maize and cotton (since 3000 BC) in return for potatoes and meat. As they had no reason to abandon their gracious mountain, the human presence in the site was persistent. Their genetic analysis show that they are more similar to Paleo-Indian Clovis culture than to other South Americans.
    <br />
    <br />
    However, despite this pastoral environment, the Andes people never developed a script or documented their history, unlike the Paleo-Indians of Mesoamerica. Until the rise of Inca in the 13th century AD, the Andes settlers were only able to advance their civilization in small steps (e.g., pottery was only introduced into Peru between 1800-1500 BC). It is also unclear when the Cuncaicha settlements were abandoned, however it is known that a climate change around 3500 BC cooled the temperature and covered the mountains with fog, pushing the settlers to relocate to the coast and specialize in fishing.
    <br />
    <br />
    Like other Paleo-Indians in Chille and Brazil, the Cuncaicha people have also believed in life after deaths and more specifically – bodily transformation. Their burials indicated a belief in bodily transformation through reduction (through the removal of body parts or cremation). The analysis of the funeral contexts revealed similarities to other contemporary sites in the Andes.
    <br />
    <br />
    The two males included in this test have the Q1 paternal and A maternal haplogroups. Haplogroup Q1 is the primary clade of haplogroup Q and is predominant among Native Americans and certain Siberian and Central American people. It has emerged in Siberia 17,000-31,700 years ago and arrived to the New World with the first settlers. Haplogroup maternal A is also most commonly found among Native Americans. It has derived from haplogroup N in Asia some 30,000–50,000 years ago and did not diversify like other haplogroups. This indicates that the descendants of this maternal haplogroup have maintained coupling within the group after they experienced a long bottleneck some 20,000 years ago.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/2IC2VyN'>"Peruvian Prehistory: An Overview of Pre-Inca and Inca Society"</a></strong>
    <br />
    <br />
    <iframe title='Peruvian Prehistory: An Overview of Pre-Inca and Inca Society' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=0521255600&asins=0521255600&linkId=e79910659a5074bd45ae2ffd9629b764&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default cuncaicha
