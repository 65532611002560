import preDorsetIcon from '../assets/images/cultures/Pre-dorset_paleo-eskimo_icon.png'
import cordedWareGermanyIcon from '../assets/images/cultures/Corded_ware_icon.png'
import bellBeakerEnglandEBAIcon from '../assets/images/cultures/Bell_beaker_eba_england_icon.png'
import bellBeakerEnglandMBAIcon from '../assets/images/cultures/Bell_beaker_mba_england.png'
import bellBeakerWalesEBAIcon from '../assets/images/cultures/Bell_beaker_eba_wales.png'
import bellBeakerWalesMBAIcon from '../assets/images/cultures/Bell_beaker_mba_wales.png'
import bellBeakerScotlandEBAIcon from '../assets/images/cultures/Bell_beaker_eba_scotland.png'
import bellBeakerScotlandLBAIcon from '../assets/images/cultures/Bell_beaker_lba_scotland.png'
import minoanIcon from '../assets/images/cultures/Minoan_icon.png'
import mycenaeanIcon from '../assets/images/cultures/Mycenaean_icon.png'
import jomonIcon from '../assets/images/cultures/Jomon_icon.png'
import ainGhazalIcon from '../assets/images/cultures/Ain_ghazal_icon.png'
import sakaIcon from '../assets/images/cultures/Saka_icon.png'
import canaanitesIcon from '../assets/images/cultures/Sidonian_canaanite-phoenician_icon.png'
import chongoniRockArtistsIcon from '../assets/images/cultures/Chongoni_icon.png'
import deerStoneIcon from '../assets/images/cultures/Deer_stone_icon.png'
import cuncaichaHighlandIcon from '../assets/images/cultures/Cuncaicha_highland_icon.png'
import southWesternIberianBronzeIcon from '../assets/images/cultures/South-western_iberian_bronze_icon.png'
import yamnayaIcon from '../assets/images/cultures/Yamnaya_icon.png'
import bellBeakerSpainIcon from '../assets/images/cultures/Spain_bell_beaker_icon.png'
import protocogotasIcon from '../assets/images/cultures/Protocogotas_icon.png'
import lapitaIcon from '../assets/images/cultures/Lapita_icon.png'
import hittiteIcon from '../assets/images/cultures/Hittite_icon.png'
import assyrianIcon from '../assets/images/cultures/Assyrian_icon.png'
import sanNicolasIslandIcon from '../assets/images/cultures/Nicoleño_icon.png'
import dongSonIcon from '../assets/images/cultures/Dong_song_icon.png'
import saqqaqIcon from '../assets/images/cultures/saqqaq.png'

import preDorsetIconSmall from '../assets/images/cultures/Pre-dorset_paleo-eskimo_icon_small.png'
import cordedWareGermanyIconSmall from '../assets/images/cultures/Corded_ware_icon_small.png'
import bellBeakerEnglandEBAIconSmall from '../assets/images/cultures/Bell_beaker_eba_england_icon_small.png'
import bellBeakerEnglandMBAIconSmall from '../assets/images/cultures/Bell_beaker_mba_england_small.png'
import bellBeakerWalesEBAIconSmall from '../assets/images/cultures/Bell_beaker_eba_wales_small.png'
import bellBeakerWalesMBAIconSmall from '../assets/images/cultures/Bell_beaker_mba_wales_small.png'
import bellBeakerScotlandEBAIconSmall from '../assets/images/cultures/Bell_beaker_eba_scotland_small.png'
import bellBeakerScotlandLBAIconSmall from '../assets/images/cultures/Bell_beaker_lba_scotland_small.png'
import minoanIconSmall from '../assets/images/cultures/Minoan_icon_small.png'
import mycenaeanIconSmall from '../assets/images/cultures/Mycenaean_icon_small.png'
import jomonIconSmall from '../assets/images/cultures/Jomon_icon_small.png'
import ainGhazalIconSmall from '../assets/images/cultures/Ain_ghazal_icon_small.png'
import sakaIconSmall from '../assets/images/cultures/Saka_icon_small.png'
import canaanitesIconSmall from '../assets/images/cultures/Sidonian_canaanite-phoenician_icon_small.png'
import chongoniRockArtistsIconSmall from '../assets/images/cultures/Chongoni_icon_small.png'
import deerStoneIconSmall from '../assets/images/cultures/Deer_stone_icon_small.png'
import cuncaichaHighlandIconSmall from '../assets/images/cultures/Cuncaicha_highland_icon_small.png'
import southWesternIberianBronzeIconSmall from '../assets/images/cultures/South-western_iberian_bronze_icon_small.png'
import yamnayaIconSmall from '../assets/images/cultures/Yamnaya_icon_small.png'
import bellBeakerSpainIconSmall from '../assets/images/cultures/Spain_bell_beaker_icon_small.png'
import protocogotasIconSmall from '../assets/images/cultures/Protocogotas_icon_small.png'
import lapitaIconSmall from '../assets/images/cultures/Lapita_icon_small.png'
import hittiteIconSmall from '../assets/images/cultures/Hittite_icon_small.png'
import assyrianIconSmall from '../assets/images/cultures/Assyrian_icon_small.png'
import sanNicolasIslandIconSmall from '../assets/images/cultures/Nicoleño_icon_small.png'
import dongSonIconSmall from '../assets/images/cultures/Dong_song_icon_small.png'
import saqqaqIconSmall from '../assets/images/cultures/saqqaq_small.png'

import preDorsetIcon200 from '../assets/images/cultures/Pre-dorset_paleo-eskimo_icon_200px.png'
import cordedWareGermanyIcon200 from '../assets/images/cultures/Corded_ware_icon_200px.png'
import bellBeakerEnglandEBAIcon200 from '../assets/images/cultures/Bell_beaker_eba_england_icon_200px.png'
import bellBeakerEnglandMBAIcon200 from '../assets/images/cultures/Bell_beaker_mba_england_200px.png'
import bellBeakerWalesEBAIcon200 from '../assets/images/cultures/Bell_beaker_eba_wales_200px.png'
import bellBeakerWalesMBAIcon200 from '../assets/images/cultures/Bell_beaker_mba_wales_200px.png'
import bellBeakerScotlandEBAIcon200 from '../assets/images/cultures/Bell_beaker_eba_scotland_200px.png'
import bellBeakerScotlandLBAIcon200 from '../assets/images/cultures/Bell_beaker_lba_scotland_200px.png'
import minoanIcon200 from '../assets/images/cultures/Minoan_icon_200px.png'
import mycenaeanIcon200 from '../assets/images/cultures/Mycenaean_icon_200px.png'
import jomonIcon200 from '../assets/images/cultures/Jomon_icon_200px.png'
import ainGhazalIcon200 from '../assets/images/cultures/Ain_ghazal_icon_200px.png'
import sakaIcon200 from '../assets/images/cultures/Saka_icon_200px.png'
import canaanitesIcon200 from '../assets/images/cultures/Sidonian_canaanite-phoenician_icon_200px.png'
import chongoniRockArtistsIcon200 from '../assets/images/cultures/Chongoni_icon_200px.png'
import deerStoneIcon200 from '../assets/images/cultures/Deer_stone_icon_200px.png'
import cuncaichaHighlandIcon200 from '../assets/images/cultures/Cuncaicha_highland_icon_200px.png'
import southWesternIberianBronzeIcon200 from '../assets/images/cultures/South-western_iberian_bronze_icon_200px.png'
import yamnayaIcon200 from '../assets/images/cultures/Yamnaya_icon_200px.png'
import bellBeakerSpainIcon200 from '../assets/images/cultures/Spain_bell_beaker_icon_200px.png'
import protocogotasIcon200 from '../assets/images/cultures/Protocogotas_icon_200px.png'
import lapitaIcon200 from '../assets/images/cultures/Lapita_icon_200px.png'
import hittiteIcon200 from '../assets/images/cultures/Hittite_icon_200px.png'
import assyrianIcon200 from '../assets/images/cultures/Assyrian_icon_200px.png'
import sanNicolasIslandIcon200 from '../assets/images/cultures/Nicoleño_icon_200px.png'
import dongSonIcon200 from '../assets/images/cultures/Dong_song_icon_200px.png'
import saqqaqIcon200 from '../assets/images/cultures/saqqaq_200px.png'

import albionAlbaEBAIconSmall from '../assets/images/genepools/albion_alba_eba_icon_small.png'
import albionAlbaIconSmall from '../assets/images/genepools/albion_alba_icon_small.png'
import atlanticCelticIconSmall from '../assets/images/genepools/antlatic_celtic_icon_small.png'
import caanAnatoliaIconSmall from '../assets/images/genepools/canaan_anatolia_icon_small.png'
import eastAfricanIconSmall from '../assets/images/genepools/east_africans_icon_small.png'
import eurasianHunterGatherersIconSmall from '../assets/images/genepools/eurasian_hunter_gatherers_icon_small.png'
import europeanBellBeakerIconSmall from '../assets/images/genepools/european_bell_beaker_icon_small.png'
import gansuIconSmall from '../assets/images/genepools/gansu_icon_small.png'
import albioAlbaLBAIconSmall from '../assets/images/genepools/lba_albion_alba_icon_small.png'
import northPaleoamericaIconSmall from '../assets/images/genepools/north_paleomerica_icon_small.png'
import northSouthPaleoamericaIconSmall from '../assets/images/genepools/north_south_paleoamerica_icon_small.png'
import yamanyaIconSmall from '../assets/images/genepools/yamnaya_icon_small.png'

import albionAlbaEBAIcon from '../assets/images/genepools/albion_alba_eba_icon_medium.png'
import albionAlbaIcon from '../assets/images/genepools/albion_alba_icon_medium.png'
import atlanticCelticIcon from '../assets/images/genepools/antlatic_celtic_icon_medium.png'
import caanAnatoliaIcon from '../assets/images/genepools/canaan_anatolia_icon_medium.png'
import eastAfricanIcon from '../assets/images/genepools/east_africans_icon_medium.png'
import eurasianHunterGatherersIcon from '../assets/images/genepools/eurasian_hunter_gatherers_icon_medium.png'
import europeanBellBeakerIcon from '../assets/images/genepools/european_bell_beaker_icon_medium.png'
import gansuIcon from '../assets/images/genepools/gansu_icon_medium.png'
import albioAlbaLBAIcon from '../assets/images/genepools/lba_albion_alba_icon_medium.png'
import northPaleoamericaIcon from '../assets/images/genepools/north_paleomerica_icon_medium.png'
import northSouthPaleoamericaIcon from '../assets/images/genepools/north_south_paleoamerica_icon_medium.png'
import yamanyaIcon from '../assets/images/genepools/yamnaya_icon_medium.png'

import preDorsetPreview from '../assets/images/cultures/pre-dorset_preview.png'
import cordedWareGermanyPreview from '../assets/images/cultures/corded-ware_germany_preview.png'
import bellBeakerEnglandEBAPreview from '../assets/images/cultures/bell-beaker_EBA_england_preview.png'
import bellBeakerEnglandMBAPreview from '../assets/images/cultures/bell-beaker_MBA_england_preview.png'
import bellBeakerWalesEBAPreview from '../assets/images/cultures/bell-beaker_EBA_wales_preview.png'
import bellBeakerWalesMBAPreview from '../assets/images/cultures/bell-beaker_MBA_wales_preview.png'
import bellBeakerScotlandEBAPreview from '../assets/images/cultures/bell-beaker_EBA_scotland_preview.png'
import bellBeakerScotlandLBAPreview from '../assets/images/cultures/bell-beaker_LBA_scotland_preview.png'
import minoanPreview from '../assets/images/cultures/minoan_preview.png'
import mycenaeanPreview from '../assets/images/cultures/mycenaean_preview.png'
import jomonPreview from '../assets/images/cultures/jomon_preview.png'
import sakaPreview from '../assets/images/cultures/saka_preview.png'
import canaanitesPreview from '../assets/images/cultures/sidonian_preview.png'
import chongoniRockArtistsPreview from '../assets/images/cultures/chongoni_preview.png'
import deerStonePreview from '../assets/images/cultures/deer-stone_preview.png'
import cuncaichaHighlandPreview from '../assets/images/cultures/cuncaicha_preview.png'
import southWesternIberianBronzePreview from '../assets/images/cultures/south-western_iberian_preview.png'
import yamnayaPreview from '../assets/images/cultures/yamnaya_preview.png'
import bellBeakerSpainPreview from '../assets/images/cultures/bell-beaker_spain_preview.png'
import protocogotasPreview from '../assets/images/cultures/protocogotas_preview.png'
import lapitaPreview from '../assets/images/cultures/lapita_preview.png'
import hittitePreview from '../assets/images/cultures/hittite_preview.png'
import assyrianPreview from '../assets/images/cultures/assyrian_preview.png'
import sanNicolasIslandPreview from '../assets/images/cultures/sn-nicolas_preview.png'
import dongSonPreview from '../assets/images/cultures/dong-son_preview.png'
import saqqaqPreview from '../assets/images/cultures/saqqaq_preview.png'
import sidonianPreview from '../assets/images/cultures/sidonian_preview.png'

export default {
  preDorsetIcon,
  cordedWareGermanyIcon,
  bellBeakerEnglandEBAIcon,
  bellBeakerEnglandMBAIcon,
  bellBeakerWalesEBAIcon,
  bellBeakerWalesMBAIcon,
  bellBeakerScotlandEBAIcon,
  bellBeakerScotlandLBAIcon,
  minoanIcon,
  mycenaeanIcon,
  jomonIcon,
  ainGhazalIcon,
  sakaIcon,
  canaanitesIcon,
  chongoniRockArtistsIcon,
  deerStoneIcon,
  cuncaichaHighlandIcon,
  southWesternIberianBronzeIcon,
  yamnayaIcon,
  bellBeakerSpainIcon,
  protocogotasIcon,
  lapitaIcon,
  hittiteIcon,
  assyrianIcon,
  sanNicolasIslandIcon,
  dongSonIcon,
  saqqaqIcon,

  preDorsetIconSmall,
  cordedWareGermanyIconSmall,
  bellBeakerEnglandEBAIconSmall,
  bellBeakerEnglandMBAIconSmall,
  bellBeakerWalesEBAIconSmall,
  bellBeakerWalesMBAIconSmall,
  bellBeakerScotlandEBAIconSmall,
  bellBeakerScotlandLBAIconSmall,
  minoanIconSmall,
  mycenaeanIconSmall,
  jomonIconSmall,
  ainGhazalIconSmall,
  sakaIconSmall,
  canaanitesIconSmall,
  chongoniRockArtistsIconSmall,
  deerStoneIconSmall,
  cuncaichaHighlandIconSmall,
  southWesternIberianBronzeIconSmall,
  yamnayaIconSmall,
  bellBeakerSpainIconSmall,
  protocogotasIconSmall,
  lapitaIconSmall,
  hittiteIconSmall,
  assyrianIconSmall,
  sanNicolasIslandIconSmall,
  dongSonIconSmall,
  saqqaqIconSmall,

  preDorsetIcon200,
  cordedWareGermanyIcon200,
  bellBeakerEnglandEBAIcon200,
  bellBeakerEnglandMBAIcon200,
  bellBeakerWalesEBAIcon200,
  bellBeakerWalesMBAIcon200,
  bellBeakerScotlandEBAIcon200,
  bellBeakerScotlandLBAIcon200,
  minoanIcon200,
  mycenaeanIcon200,
  jomonIcon200,
  ainGhazalIcon200,
  sakaIcon200,
  canaanitesIcon200,
  chongoniRockArtistsIcon200,
  deerStoneIcon200,
  cuncaichaHighlandIcon200,
  southWesternIberianBronzeIcon200,
  yamnayaIcon200,
  bellBeakerSpainIcon200,
  protocogotasIcon200,
  lapitaIcon200,
  hittiteIcon200,
  assyrianIcon200,
  sanNicolasIslandIcon200,
  dongSonIcon200,
  saqqaqIcon200,

  albionAlbaEBAIconSmall,
  albionAlbaIconSmall,
  atlanticCelticIconSmall,
  caanAnatoliaIconSmall,
  eastAfricanIconSmall,
  eurasianHunterGatherersIconSmall,
  europeanBellBeakerIconSmall,
  gansuIconSmall,
  albioAlbaLBAIconSmall,
  northPaleoamericaIconSmall,
  northSouthPaleoamericaIconSmall,
  yamanyaIconSmall,

  albionAlbaEBAIcon,
  albionAlbaIcon,
  atlanticCelticIcon,
  caanAnatoliaIcon,
  eastAfricanIcon,
  eurasianHunterGatherersIcon,
  europeanBellBeakerIcon,
  gansuIcon,
  albioAlbaLBAIcon,
  northPaleoamericaIcon,
  northSouthPaleoamericaIcon,
  yamanyaIcon,

  preDorsetPreview,
  cordedWareGermanyPreview,
  bellBeakerEnglandEBAPreview,
  bellBeakerEnglandMBAPreview,
  bellBeakerWalesEBAPreview,
  bellBeakerWalesMBAPreview,
  bellBeakerScotlandEBAPreview,
  bellBeakerScotlandLBAPreview,
  minoanPreview,
  mycenaeanPreview,
  jomonPreview,
  sakaPreview,
  canaanitesPreview,
  chongoniRockArtistsPreview,
  deerStonePreview,
  cuncaichaHighlandPreview,
  southWesternIberianBronzePreview,
  yamnayaPreview,
  bellBeakerSpainPreview,
  protocogotasPreview,
  lapitaPreview,
  hittitePreview,
  assyrianPreview,
  sanNicolasIslandPreview,
  dongSonPreview,
  saqqaqPreview,
  sidonianPreview
}
