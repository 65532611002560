import React, { useState, useEffect } from 'react'
import Papa from 'papaparse'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'

import CulturesPage from './components/cultures-page'
import CulturesPageId from './components/cultures-page-id'
import GenePoolsPage from './components/gene-pools-page'
import FaqPage from './components/faq-page'
import HomePage from './components/home-page'
import Preview from './components/preview-page'
import CopyrightsPage from './components/copyrights-page'
import { ScrollToTop } from './services/hooks'

import confCultures from './config/cultures.csv'
import confGenePools from './config/genepools.csv'
import confFaq from './config/faq.csv'
import icons from './config/icons'
import stories, { previews } from './components/stories'

import previewData from './config/preview-data'

import {
  beginnerResultMapper,
  advancedResultMapper,
  geneticDistMapper
} from './config/result-mappings'

import './App.scss'
import './styles/_sidebar.scss'

async function getData (file, setIcon = true) {
  const response = await window.fetch(file)
  const reader = response.body.getReader()
  const result = await reader.read() // raw array
  const decoder = new TextDecoder('utf-8')
  const csv = decoder.decode(result.value) // the csv text
  const results = Papa.parse(csv, { header: true, delimiter: '\t' }) // object with { data, errors, meta }
  const rows = results.data // array of objects
  rows.pop()
  return rows.map((r, idx) => {
    r.idx = idx
    if (setIcon && r.icon) {
      r.xsmallIcon = icons[`${r.icon}Small`]
      r.smallIcon = r.Culture ? icons[`${r.icon}200`] : icons[`${r.icon}Small`]
      // console.log(`${r.icon}Preview`)
      r.previewImage = r.Culture ? icons[`${r.icon.replace(/Icon$/, '')}Preview`] : null
      r.icon = icons[r.icon]
    }
    if (r.Culture) {
      r.from = -(+r.From)
      r.to = -(+r.To)
      r.linkName = r.Culture.replace(/\s/gi, '-').toLowerCase()
      r.preview = r.story ? previews[r.story] : null
      r.story = r.story ? stories[r.story] : null
    }
    return r
  })
}

let lastReportSet = null

function App () {
  const [culturesConf, setCulturesConf] = useState([])
  const [genePoolsConf, setGenePoolsConf] = useState([])
  const [faqConf, setFaqConf] = useState([])
  const [isBeginner, setIsBeginner] = useState(false)
  const [report, _setReport] = useState()
  const [isPreview, setIsPreview] = useState(true)
  const [isReadyToRender, setIsReadyToRender] = useState(false)

  const setReport = (rep) => {
    const temp = JSON.stringify(rep)
    if (!lastReportSet || lastReportSet !== temp) {
      lastReportSet = temp
      _setReport(rep)
    }
  }

  const messageListener = (event) => {
    if (event.data && event.data.source && event.data.source.startsWith('react')) return
    // console.log(event.data)
    if (!event.data.replace || event.data.reactBuildType) return
    if (event.data && typeof event.data === 'string') {
      try {
        const parsed = JSON.parse(event.data)
        let content
        if (parsed.preview) {
          setIsPreview(true)
          content = parseContent(previewData)
        } else {
          setIsPreview(false)
          content = parseContent(parsed.reportPage)
          setIsBeginner(content.isBeginner)
        }
        // console.log('setreport messageListener')
        setReport(content)
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    getData(confCultures, true).then(rows => {
      rows = rows.filter(r => {
        if (!isBeginner) return true
        else return r.beginner === '1'
      })
      setCulturesConf(rows)
    })
    getData(confGenePools, true).then(rows => {
      rows = rows.filter(r => {
        if (!isBeginner && r.advanced === '1') return true
        else return isBeginner && r.beginner === '1'
      })
      setGenePoolsConf(rows)
    })
  }, [isBeginner])

  useEffect(() => {
    if (!report || culturesConf.length === 0) return
    if (isBeginner) {
      report.result = report.beginnerResult
      report.geneticDistance = report.beginnerGDistanceResult
    } else {
      report.result = report.advancedResult
      report.geneticDistance = report.advancedGDistanceResult
    }
    const distances = culturesConf.map(c => report.geneticDistance[c.Culture])
    distances.sort((a, b) => b - a)
    for (const key in report.geneticDistance) {
      const value = report.geneticDistance[key]
      report.geneticDistance[key] = distances.indexOf(value)
    }
    culturesConf.map(c => (c.geneticDist = report.geneticDistance[c.Culture]))
  }, [isBeginner, report, culturesConf])

  useEffect(() => {
    getData(confFaq, false).then(rows => setFaqConf(rows))
  }, [])

  useEffect(() => {
    if (window.location && window.location.search) {
      if (isPreview && /app=beginner/gi.test(window.location.search)) {
        setIsBeginner(true)
      } else if (isPreview && /app=advanced/gi.test(window.location.search)) {
        setIsBeginner(false)
      }
    }
    setTimeout(() => setIsReadyToRender(true), 500)
  }, [isPreview, setIsBeginner])

  useEffect(() => {
    if (isPreview === false && report) {
      window.sessionStorage.setItem('badna', JSON.stringify([isPreview, report, isBeginner]))
    }
  }, [isPreview, report])

  useEffect(() => {
    if (isPreview === true) {
      const json = window.sessionStorage.getItem('badna')
      if (json) {
        const [isPreview, report, isBeginner] = JSON.parse(json)
        setIsPreview(isPreview)
        setIsBeginner(isBeginner)
        // console.log('setreport session')
        setReport(report)
      }
    }
  }, [isPreview])

  useEffect(() => {
    const content = parseContent(previewData)
    // console.log('setreport start with preview')
    setReport(content)
    // console.log('add message listener')
    window.addEventListener('message', messageListener)
    return () => window.removeEventListener('message', messageListener)
  }, [])

  return (
    <Router>
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      <ScrollToTop />
      <Switch>
        <Route path='/404'>
          <h1>404</h1>
          <div className='row'>
            <div className='content col-md-9'>
              <h1>Not Found</h1>
            </div>
          </div>
        </Route>
        <Route
          path='/app'
          render={(routeProps) => <Preview culturesConf={culturesConf} genePoolsConf={genePoolsConf} isBeginner={isBeginner} report={report} {...routeProps} setIsBeginner={setIsBeginner} isPreview={isPreview} />}
        />
        <Route
          path='/preview'
          render={(routeProps) => <Preview culturesConf={isReadyToRender ? culturesConf : []} genePoolsConf={genePoolsConf} isBeginner={isBeginner} report={report} {...routeProps} setIsBeginner={setIsBeginner} isPreview={isPreview} />}
        />
        <Route
          path='/faq'
          render={(routeProps) => <FaqPage faqs={faqConf} />}
        />
        <Route
          path='/gene-pools'
          render={(routeProps) => <GenePoolsPage genePools={genePoolsConf} />}
        />
        <Route
          path='/cultures/:culture'
          render={(routeProps) => <CulturesPageId cultures={culturesConf} cultureName={routeProps.match.params.culture} isPreview={isPreview} />}
        />
        <Route
          path='/cultures'
          render={(routeProps) => <CulturesPage cultures={culturesConf} />}
        />
        <Route
          path='/icons-copyrights'
          render={(routeProps) => <CopyrightsPage />}
        />
        <Route path='/' render={(routeProps) => <HomePage />} />
      </Switch>
    </Router>
  )
}

export default App

const resultLineParser = (line, resultObj, mapper) => {
  const values = line.split('\t').filter(v => v !== '')
  if (values.length === 2) {
    const [key, val] = values
    const mappedGP = mapper[key.trim()]
    resultObj[mappedGP] = +(val.trim())
  }
  if (values.length === 3) {
    const [country, culture, geneticDistance] = values
    const mappedCult = mapper[culture.trim()]
    resultObj[mappedCult] = +(geneticDistance.trim())
  }
}

function parseContent (content) {
  const beginnerResult = {}
  const advancedResult = {}
  const beginnerGDistanceResult = {}
  const advancedGDistanceResult = {}
  let isBeginner = true
  const lines = content.trim().split('\n')
  if (lines) {
    let block
    for (const line of lines) {
      if (line.startsWith('====')) {
        block = line.trim()
        continue
      }
      if (!block || line.trim() === '' || line.startsWith('BA_culture_')) continue
      if (block === '========== BA_test_GP_beginner') {
        resultLineParser(line, beginnerResult, beginnerResultMapper)
      } else if (block === '========== BA_test_GP_advanced') {
        resultLineParser(line, advancedResult, advancedResultMapper)
        isBeginner = false
      } else if (block === '========== BA_test_ClosestCulture_beginner') {
        resultLineParser(line, beginnerGDistanceResult, geneticDistMapper)
      } else if (block === '========== BA_test_ClosestCulture_advanced') {
        resultLineParser(line, advancedGDistanceResult, geneticDistMapper)
      }
    }
  }
  return {
    beginnerResult,
    advancedResult,
    beginnerGDistanceResult,
    advancedGDistanceResult,
    isBeginner
  }
}
