import React from 'react'
import Navbar from '../components/navbar'
import About from '../components/homepage/about'
import Package from '../components/homepage/package'
import Footer from '../components/footer'
// import { divIcon } from 'leaflet'

function HomePage (props) {
  return (
    <div className='homepage-wrap' id='homepage'>
      <div className='homepage-banner'>
        <Navbar />
        <div className='welcome-block'>
          <div className='welcome-text'>
            <div className='title'>MY BRONZE AGE ORIGINS TEST</div>
            <div className='sub-title'>LEARN ABOUT YOUR BRONZE AGE ORIGINS AS IF YOU WERE THERE!</div>
            <div className='test-btn'>
              <a href='#test'>Chose your test</a>
            </div>
          </div>
        </div>
      </div>
      <About />
      <Package />
      <Footer />
    </div>
  )
}

export default HomePage
