import React from 'react'
import image_01 from '../../assets/images/cultures/protocogotas_text_01.jpg'
import image_02 from '../../assets/images/cultures/protocogotas_text_02.jpg'
import image_03 from '../../assets/images/cultures/protocogotas_text_03.jpg'
import image_04 from '../../assets/images/cultures/protocogotas_text_04.jpg'

export const protocogotasPreview = 'The Spanish Protocogotas culture (Cogotas I) were pastoralist people who become culturally unified in the Iberian Peninsula. They emerged around 1700 BC and disappeared in 1000 BC, after the invasion of the Celts and the beginning of the Iron Age.'

function protocogotas (props) {
  return (<>
    <h2>Protocogotas culture (Cogotas I) in Spain ({props.From} - {props.To} B.C.)</h2>
    <br />
    {protocogotasPreview}
    <br />
    <br />
    <img src={image_01} alt='Protocogotas culture (Cogotas I) in Spain' />
    <legend> An Iberian lynx, a typical Iberian predator (source: Wikipedia)</legend>
    <br />
    <br />
    The typical Protocogotas culture artifacts were black tronco-conic pottery with geometric elements, although they varied by region. Some of the pottery found in the Balearic Islands in the Iberian peninsula shows a “dot and line” pattern.
    <br />
    <br />
    <img src={image_02} alt='Protocogotas culture (Cogotas I) in Spain' />
    <legend>Vessel from the Cueva de Boquique in Plasencia, representative of Cogotas ceramics, with decoration filled with a white body from the burial of San Román de Hornija (1100-800 BC) (source: Wikipedia)</legend>
    <br />
    They also created remarkable Bronze art. “Elbow fibula” objects from bronze have been found in many Bronze Age sites; However, it is found at the highest concentration in Iberia. In some Bronze Age sites in Mesopotamia and the Levant, it appears like a triangle, sometimes with beads on its edges. This mysterious object can be traced to the eastern Meditterenian and was even found with the Phrygian king. Fortunately, a careful evaluation of a Mesopotamian wall art revealed that the fibula was a fashion item worn as a part of the dress. This fashion must have been really appreciated in Spain since so much of it was found, until it fell out of fashion, that is.
    <br />
    <br />
    <img src={image_03} alt='Protocogotas culture (Cogotas I) in Spain' />
    <legend> “Elbow” bronze fibula from the burial of San Román de Hornija, Valladolid province from the final bronze period (1100-800 BC) (source: Wikipedia)</legend>
    <br />
    The origins of the Protocogotas culture lies 4500 years ago when a mysterious migration from the steppes along the shores of the Black and Caspian Seas changed the DNA makeup of the Iberian peninsula. The newcomers brought with them their horses and superior bronze technology, ushering the Bronze Age to the era. This allowed them to gain an advantage in trade, commerce, agriculture, and warfare. Within a short time, 40% of the DNA signature of Iberia has been replaced with the DNA signature of the newcomers. That replacement was driven by men. The most closely related modern populations to these Bronze Age equestrian people are the Basque, who are the sole speakers of a non-Indo-European language in Western Europe.
    <br />
    <br />
    Protocogotas sites have been found throughout Spain. Sites like La Requejada in Castilla y León show the remains of roundhouses and ground holes that were initially grain pits but then filled with waste materials like food remains, ashes, the broken pots. The Protocogotas adopted an old belief system that involved human sacrifice.
    <br />
    <br />
    <img src={image_04} alt='Protocogotas culture (Cogotas I) in Spain' />
    <legend> La Requejada (source: Wikipedia)</legend>
    <br />
    The two men included in this test belong to haplogroup R1b1a1a2a commonly found in Europe, Anatolia, and the Caucasus. One male carried the I2 haplogroup, which originated in Europe and is common in Eastern Europe and the Balkan. The mitochondrial haplogroups of the two males and four females included a wide variety of H, K, T, and U. Haplogroup H originated in the Middle East and is commonly found in Europe today. Haplogroup K is common in Europe today and was recently derived from U, which was the most common haplogroup among European hunter-gatherers of the Paleolithic and still exists in Europe today, although at lower frequencies due to the genetic contribution of incoming European farmers. Haplogroup T is probably Middle Eastern in origin and found today throughout Central Asia and in low rates in Europe.
    <br />
    <br />
    Protocogotas culture (Cogotas I) in Spain declined in Asia quickly as they absorbed in the general population. In Europe, they were replaced by the Romans and in the Pontic Caspian steppes they were replaced by the Bronze Age Catacomb culture, named after its more elaborated burial practices. For instances, in Catacomb graves the shaft graves included also a burial niche at their base and often included a wheeled wagon. Cattle sacrifices were also more common, indicating the growth of complexity in cultic activities.
    <br />
    <br />
    If you want to read more, we recommend reading <strong><a target='_blank' href='https://amzn.to/2HctLNw'>"El yacimiento de "La Dehesa" Una aproximación al mundo funerario Protocogotas (Spanish Edition)"</a></strong>
    <br />
    <br />
    <iframe title='El yacimiento de "La Dehesa" Una aproximación al mundo funerario Protocogotas (Spanish Edition)' className='mt15 flex justify-center' style={{ width: '120px', height: '240px' }} marginWidth='0' marginHeight='0' scrolling='no' frameBorder='0' src='//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=geneplaza09-20&marketplace=amazon&region=US&placement=B00YGNTTTA&asins=B00YGNTTTA&linkId=655322592471593d88fbe42e42617c64&show_border=false&link_opens_in_new_window=false&price_color=ffffff&title_color=ffffff&bg_color=24354c' />
  </>)
}
export default protocogotas
