const previewData = `
0.000010 0.011542 0.009626 0.026039 0.009638 0.052709 0.041007 0.127269 0.002906 0.008559 0.003792 0.003782 0.266888 0.063786 0.041332 0.040952 0.015840 0.043308 0.025113 0.205902
========== BA_test_GP_beginner
1	0
2	0.665
3	0.013
4	0.255
5	0.008
6	0.058
========== BA_test_GP_advanced
1	0
2	0.497
3	0.26
4	0.014
5	0.08
6	0.052
7	0.02
8	0.005
9	0.005
10	0.067
========== BA_test_ClosestCulture_beginner
BA_culture_Beginner_text.Country	BA_culture_Beginner_text.Culture	genetic_dist
Canada	Early Paleo-Eskimo culture	0.915252424197828
Czech Republic, Germany	Corded Ware culture	0.0829879509326505
England	Bell Beaker culture England EBA	0.845486250627413
England	Bell Beaker culture England MBA	0.85208391605522
England	Bell Beaker culture Wales EBA	1.00042341036183
England	Bell Beaker culture Wales MBA	0.627094091823548
England	Bell Beaker culture Scotland EBA	1.00042341036183
England	Bell Beaker culture Scotland LBA	0.803272680974524
Greece	Minoan	0.95081386190989
Greece	Mycenaean	0.95081386190989
Japan	Jomon culture	1.06791713161649
Jordan	Ain Ghazal culutre	0.565426387781823
Kazakhstan	Saka culture	0.579022452069002
Lebanon	Canaanites	1.21001942133174
Malawi	Chencherere culture	0.951339581852873
Mongolia	Deer Stone culture	1.0571882519211
Peru	The Cuncaicha Highland settlements	1.22263935811015
Portugal	South-Western Iberian Bronze	0.125566715334917
Russia	Sintashta culture	0.0814063879557372
Russia	Poltavka culture	0.315034918699499
Russia, Ukraine	Yamnaya culture	0.416157422137345
Spain	Bell beaker in Spain	0.207959130600221
Spain	Protocogotas culture (Cogotas I)	0.266170997668792
Tonga	Lapita culture	0.825037574901895
Turkey	Hittite	1.21854298241794
Turkey	Assyrian	1.21001942133174
US	San Nicolas Island culture	1.02401513660688
Vietnam	Dong Son Culture	1.18103640926095
========== BA_test_ClosestCulture_advanced
BA_culture_Advanced_text.Country	BA_culture_Advanced_text.Culture	genetic_dist
Canada	Early Paleo-Eskimo culture	0.891363001251454
Czech Republic, Germany	Corded Ware culture	0.122425487542423
England	Bell Beaker culture England EBA	0.685206538205817
England	Bell Beaker culture England MBA	0.587969386958199
England	Bell Beaker culture Wales EBA	1.08113273930633
England	Bell Beaker culture Wales MBA	0.523686929376703
England	Bell Beaker culture Scotland EBA	1.00486217960475
England	Bell Beaker culture Scotland LBA	0.486834674196487
Greece	Minoan	0.887495352100505
Greece	Mycenaean	0.887495352100505
Japan	Jomon culture	0.971085990013243
Jordan	Ain Ghazal culutre	0.575350328061087
Kazakhstan	Saka culture	0.549825426840193
Lebanon	Canaanites	1.14054723707526
Malawi	Chencherere culture	0.937543598986202
Mongolia	Deer Stone culture	0.960191647537095
Peru	The Cuncaicha Highland settlements	0.99942383401638
Portugal	South-Western Iberian Bronze	0.322037264924418
Russia	Sintashta culture	0.230408333182635
Russia	Poltavka culture	0.776136585917711
Russia, Ukraine	Yamnaya culture	0.858153832363406
Spain	Bell beaker in Spain	0.445789187845556
Spain	Protocogotas culture (Cogotas I)	0.513115971296938
Tonga	Lapita culture	0.757659554153447
Turkey	Hittite	1.14054723707526
Turkey	Assyrian	1.14054723707526
US	San Nicolas Island culture	0.882580307960698
Vietnam	Dong Son Culture	1.09309103006108

`

export default previewData
