import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
// import geneIcon from '../assets/images/genepools/middle-east.png'

function GenePoolsPage (props) {
  const { genePools = [] } = props
  console.log(genePools)
  return (
    <div className='gene-pool-page page-wrap'>
      <Navbar />
      <div className='page-header-wrap genepool-head'>
        <div className='content'>
          <div className='page-title'>
            <h1>Bronze Age Gene Pools{/* <span></span> */}</h1>
          </div>
        </div>
      </div>
      <div className='content-row gene-info'>
        <div className='content-block'>
          <div className='description t-justify'>
            By analyzing over 500 Bronze Age individuals, we identified ten regional ancestries or gene pools that distinguish past populations. Six of those gene pools are available in the Beginner version of the test, and all ten are available in the advanced version. The genome of everyone, ancient or modern person, is represented as a combination of these gene pools (ranging from 0% to 100%). Different cultures consist of varying proportions of these regional components, which allows us to study them and compare them. This also allows us to find the most genetically similar Bronze Age cultures to your Bronze Age genomic makeup.
          </div>
          <div className='description t-justify'>
            A gene pool is the collection of the population’s DNA in a particular area at a given time. The composition of gene pools can change over time through natural forces like genetic drift, where DNA changes at random sites, or through migration when a large population from a different gene pool migrates to the area and mixes its DNA with the DNA of the local community. As a result, populations are expected to exhibit a multitude of gene pools, dependent upon their demographic history. Homogeneous populations will show fewer gene pools than heterogeneous ones. The Bronze Age gene pools formed prior to and during the Bronze Age and continued to be modified afterward. Very few of those gene pools survived to the modern era. My Bronze Age Origins Test analyzes those few ancient DNA markers that survived in your DNA as if you lived in the Bronze Age
          </div>
        </div>
      </div>
      {genePools.map((gp) =>
        <div key={'gp-page-' + gp.idx} className='content-row'>
          <div className='content-block'>
            {gp.icon && <div className='icon'><img src={gp.icon} alt='gp-icon' /></div>}
            <div className='t-b title' id={'gp-' + gp.idx}>{gp.name}</div>
            <div className='line mb15' style={{ borderColor: gp.color }} />
            {gp.region && <div className='t-c t-b mb15 t-black'>{gp.region}</div>}
            <div className='description t-justify'>{gp.story}</div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  )
}

export default GenePoolsPage
